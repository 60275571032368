import loadable from '@loadable/component'
import HqPage from 'components/GlobalNav/HqPage'
import { appPrefix } from 'utils/browserHelper'

const Home = loadable(() => import(/* webpackChunkName: "FlightsHome" */ 'pages/flights/home'))
const Results = loadable(() => import(/* webpackChunkName: "FlightsResults" */ 'pages/flights/results'))

const ItineraryLoading = loadable(() => import(/* webpackChunkName: "FlightsResults" */ 'components/ItineraryLoading'))
const ItineraryFailure = loadable(() =>
    import(/* webpackChunkName: "FlightsResults" */ 'components/Itinerary/ItineraryFailure')
)

// const MerchandisingBanners = loadable(() =>
//     import(/* webpackChunkName: "MerchandisingBanners" */ 'pages/flights/results/resultsWithBanners')
// )

const HiFiveModal = loadable(() => import(/* webpackChunkName: "HiFiveModal" */ 'components/HiFivePageModal'))

// import Home from 'pages/flights/home'
// import Results from 'pages/flights/results'

const Itinerary = loadable(() => import(/* webpackChunkName: "Itinerary" */ 'pages/itinerary'))

const confirmation = loadable(() => import(/* webpackChunkName: "Itinerary" */ 'pages/itinerary/Confirmation'))

const BookingFailed = loadable(() =>
    import(/* webpackChunkName: "Itinerary" */ 'pages/itinerary/errorPages/BookingFailed')
)

const PaymentFailed = loadable(() =>
    import(/* webpackChunkName: "Itinerary" */ 'pages/itinerary/errorPages/PaymentFailed')
)

export default [
    {
        path: `${appPrefix}/`,
        component: Home,
        exact: true
    },
    {
        path: `${appPrefix}/flights`,
        component: Home,
        exact: true
    },
    {
        path: `${appPrefix}/do/search/flights`,
        component: Home,
        exact: true
    },
    {
        path: `${appPrefix}/flights/results/home`,
        component: Home,
        exact: true
    },
    {
        path: `${appPrefix}/flights/results`,
        component: Results,
        exact: true
    },
    {
        path: `${appPrefix}/flights/international/results`,
        component: Results,
        exact: true
    },
    // {
    //     path: '/flights/results/merchandisingBanners',
    //     component: MerchandisingBanners,
    //     exact: true
    // },
    {
        path: `${appPrefix}/flights/results/itinerary/loading`,
        component: ItineraryLoading,
        exact: true
    },
    {
        path: `${appPrefix}/flights/results/itinerary/failure`,
        component: ItineraryFailure,
        exact: true
    },
    {
        path: `${appPrefix}/flights/itinerary/:id/info`,
        component: Itinerary,
        exact: true
    },
    {
        path: `${appPrefix}/flights/itinerary/:id/bookConfirm`,
        component: confirmation,
        exact: true
    },
    {
        path: '/flights/itinerary/:id/payment-failed',
        component: PaymentFailed,
        exact: true
    },
    {
        path: '/flights/itinerary/:id/failure',
        component: PaymentFailed,
        exact: true
    },
    {
        path: '/flights/itinerary/:id/book-failed',
        component: BookingFailed,
        exact: true
    },
    {
        path: `${appPrefix}/ref/:id`,
        component: HiFiveModal,
        exact: true
    },
    {
        path: `${appPrefix}/signin`,
        component: HqPage,
        exact:true
    }
]
