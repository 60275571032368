// Clevertap stuff
import { getCookie, resolveKeysInObjectsArrays, isEmpty, isUserSignedIn, isCtUser } from 'utils/browserHelper'
import ClevertapReact from './clevertap'

const CLEVERTAP_KEY = process.env.CT_PUBLIC_CLEVERTAP_KEY || ''

class Clevertap {
    constructor() {
        this.enabled = false
        if (CLEVERTAP_KEY) {
            this.enabled = true
            ClevertapReact.initialize(CLEVERTAP_KEY)
        }
    }

    event = (name, payload = {}) => {
        try {
            if (this.enabled) {
                const domain = resolveKeysInObjectsArrays(['location', 'hostname'], window)
                const ctAb = isEmpty(getCookie('ct-ab')) ? {} : JSON.parse(decodeURIComponent(getCookie('ct-ab')))
                payload.platform = 'DESKTOP'
                payload.domainName = domain || 'NA'
                payload.isCtLoginUser = isCtUser()
                payload.is_logged_in = isUserSignedIn() ? 'yes' : 'no'
                payload.fareFamily_abcookie = ctAb.fareFamily || 'N/A'
                setTimeout(() => {
                    ClevertapReact.event(name, payload)
                }, 300)
            }
        } catch (e) {
            console.error(e)
        }
    }

    profile = payload => {
        if (this.enabled) {
            ClevertapReact.profile(payload)
        }
    }

    logout = () => {
        if (this.enabled) {
            ClevertapReact.logout()
        }
    }
}

const ClevertapObject = new Clevertap()
export default ClevertapObject
