/* eslint-disable no-nested-ternary */
/* eslint-disable no-restricted-syntax */
import logError from 'utils/logError'
import {
    readParam,
    FULL_NAMES_OF_DAYS,
    isEmpty,
    getUserAuthValues,
    getCookie,
    capitalizedFirst,
    getURLSearchParam,
    isUserSignedIn
} from 'utils/browserHelper'
import { getCurrencyByLocale, formatCurrency } from 'utils/currencyHelper'
import { getFormattedDate, minutesToHnM } from 'utils/dateHelper'
import { getFormattedTime } from 'utils/timeHelper'
import { getCurrencySymbol } from 'utils/currencyHelper.js'
import { replacePlaceHolder } from 'utils/uiHelper'
import { getflightsHistory, updateHomeSearch } from 'components/ModifySearch/recentSearch'
const emptyValue = 'N/A'

function onlyUnique(value, index, self) {
    return self.indexOf(value) === index
}

export function getInitialDataLayerParams(page) {
    try {
        const { country, currency } = getCurrencyByLocale()
        const utmDefault = emptyValue

        let baseObj = {
            Domain: window.location.host,
            Country: country,
            Language: process.env.LOCALE_LANG.toUpperCase() || 'EN',
            'Device-Type': 'Desktop'
        }
        if (page === 'home') {
            return baseObj
        }
        baseObj.Product = 'Air'
        if (page === 'flights') {
            return baseObj
        }
        if (page === 'traveller') {
            baseObj['Payment-Type'] = emptyValue
        }
        readParam('utm_source', { expiry: 30 })
        readParam('utm_campaign', { expiry: 30 })
        readParam('utm_medium', { expiry: 30 })
        readParam('utm_term', { expiry: 30 })
        readParam('utm_uid', { expiry: 30 })
        baseObj = {
            ...baseObj,
            Currency: currency,
            'Source-Affiliatefirstsource':
                readParam('Source-Affiliatefirstsource', {
                    alias: 'utm_source',
                    expiry: 1
                }) || utmDefault,
            'Campaign-Affiliatefirstsource':
                readParam('Campaign-Affiliatefirstsource', {
                    alias: 'utm_campaign',
                    expiry: 1
                }) || utmDefault,
            'Medium-Affiliatefirstsource':
                readParam('Medium-Affiliatefirstsource', {
                    alias: 'utm_medium',
                    expiry: 1
                }) || utmDefault,
            'Term-Affiliatefirstsource':
                readParam('Term-Affiliatefirstsource', {
                    alias: 'utm_term',
                    expiry: 1
                }) || utmDefault,
            'Uid-Affiliatefirstsource':
                readParam('Uid-Affiliatefirstsource', {
                    alias: 'utm_uid',
                    expiry: 1
                }) || utmDefault,
            'Source-Metafirstsource':
                readParam('Source-Metafirstsource', {
                    alias: 'utm_source',
                    expiry: 30
                }) || utmDefault
        }
        return baseObj
    } catch (e) {
        logError(e)
        return {}
    }
}

export function dataLayerFlightValues({ searchQuery }) {
    try {
        const {
            adults,
            childs,
            infants,
            from,
            to,
            depart_date: departDate,
            return_date: returnDate,
            intl,
            class: bookingClass,
            multicity
        } = searchQuery

        const totalPax = adults + childs + infants
        // TODO when itineary created, count added for all sector
        // segmentCount *= totalPax

        let journeyType = emptyValue

        if (returnDate) {
            journeyType = 'Return'
        } else if (departDate) {
            journeyType = 'One Way'
        }
        if (multicity) {
            journeyType = 'Multi City'
        }
        const initParams = getInitialDataLayerParams('srp')
        return {
            ...initParams,
            'First-Departure-Date': departDate ? departDate.split('/').join('-') : emptyValue,
            'Final-Departure-Date': returnDate ? returnDate.split('/').join('-') : emptyValue,
            'From-Location': from || emptyValue,
            'To-Location': to || emptyValue,
            'Journey-Type': journeyType,
            Category: intl === 'n' ? 'Domestic' : 'International',
            'Adult-Count': adults || emptyValue,
            'Child-Count': adults ? childs : emptyValue,
            'Infant-Count': adults ? infants : emptyValue,
            'Total-Passengers': totalPax || emptyValue,
            'Booking-Class': bookingClass || emptyValue,
            'Segment-Count': emptyValue,
            'Date-Search': getFormattedDate(new Date(), 'DD-MM-YYYY'),
            'Day-Search': FULL_NAMES_OF_DAYS[new Date().getDay()]
        }
    } catch (e) {
        logError(e)
        return {}
    }
}

export function dataLayerItineraryValues(params) {
    try {
        const { ItinearyData, priceSummary, splRt, getUpdateItinerary, page, selectedInsuranceDetails } = params
        let initParams = {}
        if (page && page.value) {
            initParams = {
                ...initParams,
                ...getInitialDataLayerParams(page.value)
            }
        }
        const { arrivalDate, departDate, from, to, international, paxInfo, multiCity } =
            !isEmpty(ItinearyData) && ItinearyData.searchCriteria ? ItinearyData.searchCriteria : {}

        let {
            fareDetails = {},
            searchCriteria = {},
            couponDiscountAmount = 0,
            appliedCoupon = '',
            gvDiscountAmount = 0,
            paybackDiscount = 0,
            TRAVEL_INSURANCE: travelIns = 0,
            BAGGAGE_INSURANCE: baggageIns = 0,
            MEDICAL_INSURANCE: medicalIns = 0,
            walletPromo,
            meals: mealsPrice = 0,
            baggage: baggagePrice = 0,
            seats: seatPrice = 0,
            Baggage_Protection: hepstarBaggageProtection = 0
        } = priceSummary || {}

        const { basePrice, taxes } = fareDetails

        if (walletPromo) {
            couponDiscountAmount = 0
        }
        let splRtDiscount = 0
        if (
            !isEmpty(splRt) &&
            getUpdateItinerary &&
            getUpdateItinerary.fareSelectionRequest &&
            getUpdateItinerary.fareSelectionRequest.fareKeys &&
            getUpdateItinerary.fareSelectionRequest.fareKeys[0] !== undefined &&
            getUpdateItinerary.fareSelectionRequest.fareKeys[1] !== undefined
        ) {
            const fareKeys = [...getUpdateItinerary.fareSelectionRequest.fareKeys]
            if (splRt[fareKeys[0]].fareClass === 'SPECIAL_RT' || splRt[fareKeys[1]].fareClass === 'SPECIAL_RT') {
                splRtDiscount = splRt.onward[0][0].diff + splRt.return[0][0].diff
            }
        }
        const effectivePrice =
            fareDetails.price -
            couponDiscountAmount +
            travelIns +
            baggageIns +
            hepstarBaggageProtection +
            medicalIns -
            splRtDiscount +
            mealsPrice +
            baggagePrice +
            seatPrice

        let finalPrice = effectivePrice - gvDiscountAmount
        if (finalPrice > 0) {
            finalPrice -= paybackDiscount
        }
        if (finalPrice < 0) finalPrice = 0

        const segmentXAirline = []
        const segmentXFlightNum = []
        const segmentXDepartDate = []
        const segmentXArrivalDate = []
        const bookingClass = []
        const airlineName = []
        let segCount = 0

        if (!isEmpty(ItinearyData) && ItinearyData.flights) {
            for (const flight of ItinearyData.flights) {
                for (const segment of flight.segments) {
                    segCount += 1
                    if (segment.airlineName) airlineName.push(segment.airlineName)
                    if (segment.airline) segmentXAirline.push(segment.airline)
                    if (segment.flightNumber)
                        segmentXFlightNum.push(`${segment.airline ? `${segment.airline}-` : ''}${segment.flightNumber}`)
                    if (segment.departDateTime)
                        segmentXDepartDate.push(getFormattedDate(segment.departDateTime, 'DD-MM-YYYY'))
                    if (segment.arrivalDateTime)
                        segmentXArrivalDate.push(getFormattedDate(segment.arrivalDateTime, 'DD-MM-YYYY'))
                    if (segment.cabinType) bookingClass.push(segment.cabinType)
                }
            }
        }

        let timeDiff = 0
        if (departDate) {
            timeDiff = Math.floor((new Date(departDate) - new Date()) / (1000 * 60 * 60 * 24))
            timeDiff = timeDiff < 0 ? 0 : timeDiff
        }

        const insuranceProd = []
        const insuranceCost = []
        if (selectedInsuranceDetails) {
            Object.keys(selectedInsuranceDetails).map(key => {
                if (selectedInsuranceDetails[key].isAdded) {
                    const { provider } = selectedInsuranceDetails[key]
                    insuranceProd.push(provider)
                    let localValueForVasType = key
                    if (provider === 'HEPSTAR_BRB') {
                        localValueForVasType = 'Baggage_Protection'
                    }
                    const cost = priceSummary[localValueForVasType] || emptyValue
                    insuranceCost.push(cost)
                }
            })
        }
        const { adults, children, infants } = paxInfo || {}
        let journeyType = emptyValue

        if (arrivalDate) {
            journeyType = 'Return'
        } else if (departDate) {
            journeyType = 'One Way'
        }
        if (multiCity) journeyType = 'Multi City'
        const { userId = '' } = getUserAuthValues()
        return {
            ...initParams,
            'userId':userId,
            'From-Location': from || emptyValue,
            'To-Location': to || emptyValue,
            'Journey-Type': journeyType,
            Category: international ? 'International' : 'Domestic',
            'Adult-Count': adults,
            'Child-Count': children,
            'Infant-Count': infants,
            'Total-Passengers': adults + children + infants,
            'Booking-Class': bookingClass.length > 0 ? bookingClass.filter(onlyUnique).join('|') : emptyValue,
            'First-Departure-Date': departDate ? getFormattedDate(new Date(departDate), 'DD-MM-YYYY') : emptyValue,
            'Final-Departure-Date': arrivalDate ? getFormattedDate(new Date(arrivalDate), 'DD-MM-YYYY') : emptyValue,
            'Segment-Count': segCount > 0 ? segCount * (adults + children + infants) : emptyValue,
            'Booking-Price': finalPrice,
            'Base-Fare': basePrice === null || basePrice === undefined ? emptyValue : basePrice,
            Tax: taxes === undefined || taxes === null ? emptyValue : taxes,
            Discount: emptyValue,
            'Wallet-Credit': priceSummary.walletCreditAmount || emptyValue,
            'Convenience-Fee': emptyValue,
            Coupon:
                priceSummary.appliedCoupon && priceSummary.appliedCoupon.length > 0
                    ? typeof priceSummary.appliedCoupon === 'string'
                        ? priceSummary.appliedCoupon
                        : priceSummary.appliedCoupon.join('|')
                    : emptyValue,
            'Insurance-Product': insuranceProd.length > 0 ? insuranceProd.join('|') : emptyValue,
            'Insurance-Cost': insuranceCost.length > 0 ? insuranceCost.join('|') : emptyValue,
            'Itinerary-ID': !isEmpty(ItinearyData) && ItinearyData.itineraryId ? ItinearyData.itineraryId : emptyValue,
            Airline: airlineName.length > 0 ? airlineName.filter(onlyUnique).join('|') : emptyValue,
            'D+X': departDate ? timeDiff : emptyValue,
            'Segment-X-Airline': segmentXAirline.length > 0 ? segmentXAirline.join('|') : emptyValue,
            'Segment-X-Flight-Number': segmentXFlightNum.length > 0 ? segmentXFlightNum.join('|') : emptyValue,
            'Segment-X-Departure-Date': segmentXDepartDate.length > 0 ? segmentXDepartDate.join('|') : emptyValue,
            'Segment-X-Arrival-Date': segmentXArrivalDate.length > 0 ? segmentXArrivalDate.join('|') : emptyValue
        }
    } catch (e) {
        logError(e)
        return {}
    }
}

export const dataLayerGA = (payload, event) => {
    const { dataLayer = [] } = window || {}
    const { userId } = getUserAuthValues()
    const finalPayload = {
        event: event,
        platform: 'website',
        user_status: isUserSignedIn() ? 'LoggedIn' : 'LoggedOut',
        ...payload
    }
    if(userId) {
        finalPayload.user_id = userId
    }
    dataLayer.push(finalPayload) 
}

export function GaDataPush() {
    const { dataLayer = [] } = window || {}
    const { userId } = getUserAuthValues()
    arguments[2] = {
        ...arguments[2],
        platform: 'website',
        user_status: isUserSignedIn() ? 'LoggedIn' : 'LoggedOut',
    }
    if(userId) {
        arguments[2] = {
            ...arguments[2],
            user_id: userId
        }
    }
    dataLayer.push(arguments);
}

export const getAirlineNames = (list, airlineNames) => {
    let res = ''
    if (list.length > 1) {
        list.map(item => {
            if (Object.keys(airlineNames).includes(item?.flightNumber?.toString()?.split('-')?.[0])) {
                res = res + `${airlineNames[(item?.flightNumber?.toString()?.split('-')?.[0])]}|`
            }
        })
    } else {
        res = airlineNames[(list?.[0]?.flightNumber?.toString()?.split('-')?.[0])]
    }

    return res
}

export const getID = list => {
    let res = ''
    list.map(item => {
        res = res + `${item.flightNumber}|`
    })
    return res
}
export const ecommerceGA = payload => {
    const {
        event,
        booking_date,
        flight_type,
        item_list_name,
        item_list_id,
        loopArray,
        item_name,
        item_id,
        currency,
        discount,
        index,
        price,
        item_category,
        item_category2,
        item_category3,
        item_category4,
        item_category5,
        baggage_rs,
        fare_type,
        supercoin,
        trip_type,
        base_fare,
        meal_price,
        seat_price,
        fare_value,
        source_destination
    } = payload
    try {
        const baseObj = {
            event: event,
            platform: 'website',
            booking_date: booking_date,
            base_fare: base_fare || 0,
            meal_price: meal_price || 0,
            seat_price: seat_price || 0,
            flight_type: flight_type,
            fare_value: fare_value || 0,
            source_destination: source_destination || 'N/A',
            ...(trip_type && { trip_type: trip_type || 'N/A' }),
            ...((supercoin || supercoin === 0) && { supercoin: supercoin || 0 }),
            ...(fare_type && { fare_type: fare_type || 'N/A' }),
            ...((baggage_rs || baggage_rs === 0) && { baggage_rs: baggage_rs || 0 })
        }
        const ecommerce = []
        const item = {
            item_name: item_name,
            item_id: item_id,
            currency: currency,
            discount: discount || 0,
            index: index || 'N/A',
            price: price,
            item_category: item_category,
            item_category2: item_category2,
            item_category3: item_category3 || 'N/A',
            item_category4: item_category4,
            item_category5: String(item_category5),
            item_list_name: item_list_name || 'N/A',
            item_list_id: item_list_id || 'N/A'
        }
        ecommerce.push(item)
        let final = {}
        if (loopArray?.length > 0) {
            final['items'] = loopArray
        } else {
            final['items'] = ecommerce
        }

        let result = {
            ...baseObj,
            ecommerce: final
        }
        return result
    } catch (e) {
        logError(e)
        return {}
    }
}
export const priceConvertCurrency = (price, currencyCode, rate, currency) => {
    const sellingRate = 1
    const priceValue = formatCurrency({ price, currencyCode, rate, sellingRate })
        ?.toString()
        ?.trim()
        ?.split(/\s+/)
    // return priceValue.toString()
    if (priceValue && priceValue?.length > 0) {
        return Number(priceValue?.join('').replace(/[^0-9\.-]+/g, ''))
    }
}

export const getFlightDetail = (section, key) => {
    let res = []
    section &&
        section.map(list => {
            if (key === 'flightNumber') {
                if (list?.segments?.length > 1) {
                    list?.segments?.map(item => {
                        res.push(`${item?.airline}-${item[`${key}`]}`)
                    })
                } else {
                    res.push(`${list?.segments?.[0]?.airline}-${list?.segments?.[0][`${key}`]}`)
                }
            } else {
                if (list?.segments?.length > 1) {
                    list?.segments?.map(item => {
                        res.push(`${item[`${key}`]}`)
                    })
                } else {
                    res.push(list?.segments?.[0][`${key}`])
                }
            }
        })

    if (res?.length > 1) {
        return res.join('|')
    } else {
        return res.toString()
    }
}
export const customRoundOf = (num, value) => {
    if (Number.isInteger(num)) {
        return 0
    }
    const decimalValue = (num % 1) > value ? 1 : 0
    const intStr = Number(num.toString().split('.')[0])
    let result = decimalValue + intStr
    return result

}

export const srpScrollHandle = async (
    // reactListItems,
    stickyHeight,
    range,
    cachedScrollPosition,
    isLastItemVisible,
    itemHeight,
    splRtContainerHeight,
    indexOfLeftTupple,
    groupTupleTop,
    groupTupleBottom,
    splrtCardLength,
    tuppleColumn,
) => {
    try {
        let hiddenItems = 0
        let visibleRange = range
        let firstIndex = range?.[0]
        let lastIndex = range?.[1]
        let itemFraction = 0
        let itemsBelowSticky = stickyHeight > 0 && cachedScrollPosition < 0
        let itemsScrolledSticky = stickyHeight > 0 && cachedScrollPosition > 0
        let isSplRt = splrtCardLength > 0 && splRtContainerHeight > 0
        let splRtOverlapping = groupTupleTop < stickyHeight && groupTupleBottom > stickyHeight
        let splRtfitSticky = groupTupleTop < stickyHeight && groupTupleBottom < stickyHeight && groupTupleTop > 0
        let splRtOverlapsNeg = groupTupleTop < 0 && groupTupleBottom > 0 && groupTupleBottom < stickyHeight
        if (itemsBelowSticky && isSplRt && splRtOverlapping) {
            itemFraction = (groupTupleTop + cachedScrollPosition) / itemHeight
        }
        else if (itemsBelowSticky && isSplRt && splRtfitSticky) {
            itemFraction = ((stickyHeight + cachedScrollPosition) - splRtContainerHeight) / itemHeight

        } else if (itemsScrolledSticky && isSplRt && splRtOverlapping) {
            itemFraction = groupTupleTop / itemHeight

        } else if (itemsScrolledSticky && isSplRt && splRtfitSticky) {
            itemFraction = (stickyHeight - splRtContainerHeight) / itemHeight

        } else if (itemsScrolledSticky && isSplRt && splRtOverlapsNeg) {
            itemFraction = (stickyHeight - groupTupleBottom) / itemHeight

        } else if (itemsScrolledSticky && tuppleColumn === 0) {
            itemFraction = stickyHeight / itemHeight

        } else if (itemsScrolledSticky) {
            itemFraction = stickyHeight / itemHeight

        } else if (itemsBelowSticky) {
            itemFraction = (stickyHeight + cachedScrollPosition) / itemHeight

        }
        if (itemsBelowSticky && isSplRt && splRtOverlapping && indexOfLeftTupple > 0) {
            hiddenItems = customRoundOf(itemFraction, 0.35)
            firstIndex = indexOfLeftTupple

        } else {
            hiddenItems = customRoundOf(itemFraction, 0.35)
            firstIndex = visibleRange?.[0] + hiddenItems
        }
        let updatedVisibleRange = [firstIndex, isLastItemVisible ? lastIndex + 1 : lastIndex]
        if (lastIndex > 0) {

            return updatedVisibleRange
        }

    } catch (error) {
        logError(error)
    }

}
export const getGaItemsSplRtArray = (column, resultsData, searchQuery, filteredId, conversionRate) => {
    try {
        const { sectors = {}, jsons = {} } = resultsData || {}
        const { airline_names: airlineNames, searchType } = jsons || {}
        const { adults, childs, sellingCountry, from, to, depart_date, return_date } = searchQuery

        const gaItemsArray = resultsData?.cards[column]
            .filter(item => filteredId.includes(item.id))
            .sort((a, b) => filteredId.indexOf(a.id) - filteredId.indexOf(b.id))
            .map(({ sectorKeys, priceBreakup, airlineCodes, totalDurationInMinutes }, index) => ({
                item_name: jsons?.airline_names[airlineCodes[0]] || 'N/A',
                item_id: sectors[sectorKeys?.[0]]?.flights?.segments[0]?.flightNumber,
                item_category: `${sectors[sectorKeys?.[0]]?.firstDeparture?.airportCode} | ${sectors[sectorKeys[0]].lastArrival?.airportCode}`,
                item_category2: minutesToHnM(totalDurationInMinutes),
                item_category3: 'N/A',
                item_category4: `${adults} | ${childs}`,
                item_category5: String(sectors[sectorKeys?.[0]]?.stops),
                item_list_name: 'flight search',
                item_list_id: 'flight_search',
                // price: priceBreakup?.fare?.pr,
                price: priceBreakup?.fare?.pr,
                discount: priceBreakup?.fare?.ds,
                // discount: priceConvertCurrency(priceBreakup?.fare?.ds,currencyCode, rate, true),
                index: index + 1,
                currency: getCurrencyByLocale().currency
            }))
        return gaItemsArray
    } catch (error) {
        logError(error)

    }

}

export const getOfferData = (coupon_detail, isIntl, currencyCode, rate) => {
    let intlOfferData = "Get at {discountedPrice} with {couponCode}"
    let message = (isIntl === 'y' && (coupon_detail?.discountedPrice || coupon_detail?.discountAmount)) ? intlOfferData : coupon_detail?.message

    let result = replacePlaceHolder(message, {
        discountedPrice: coupon_detail?.discountedPrice,
        discountAmount: coupon_detail?.discountAmount,
        couponCode: coupon_detail?.couponCode
    })
    return result
}

export const getAirNames = (airlineCode, nameArray) => {
    let result = airlineCode.map(item => {
        return nameArray[item]
    }).join(' | ')

    return result
}

export const getGaItemsArray = async (startingIndex, column, resultsData, searchQuery, sortedFilteredCardIds, filteredId, tripType, conversionRate) => {
    try {
        const { sectors = {}, jsons = {} } = resultsData || {}
        let updatedIds = (tripType === 'round') && (searchQuery?.intl === 'n') ? sortedFilteredCardIds[column]?.filter(item => !filteredId?.includes(item)) : sortedFilteredCardIds?.filter(item => !filteredId?.includes(item))
        const { code: currencyCode, rate = '1' } = conversionRate || {}
        const { airline_names: airlineNames, searchType } = jsons || {}
        const { adults, childs, sellingCountry, from, to, depart_date, return_date } = searchQuery
        const gaItemsArray = resultsData?.cards[column]
            .filter(item => updatedIds.includes(item.id))
            .sort((a, b) => updatedIds.indexOf(a.id) - updatedIds.indexOf(b.id))
            .map(({ sectorKeys, priceBreakup, airlineCodes, totalDurationInMinutes, maxStopsInSectors }, index) => ({
                item_name: getAirNames(airlineCodes, jsons?.airline_names) || 'N/A',
                item_id: sectors[sectorKeys[0]]?.flights?.segments[0]?.flightNumber,
                item_category: `${sectors[sectorKeys[0]]?.firstDeparture?.airportCode} | ${sectors[sectorKeys[0]].lastArrival?.airportCode}`,
                item_category2: minutesToHnM(totalDurationInMinutes),
                item_category3: getOfferData(priceBreakup?.fare?.coupon_detail, searchQuery?.intl, currencyCode, rate) || 'N/A',
                item_category4: `${adults} | ${childs}`,
                item_category5: String(maxStopsInSectors),
                item_list_name: 'flight search',
                item_list_id: 'flight_search',
                price: priceBreakup?.fare?.pr,
                discount: priceBreakup?.fare?.ds,
                index: index + startingIndex + 1,
                currency: getCurrencyByLocale().currency
            }))

        return gaItemsArray

    } catch (error) {
        logError(error)
    }

}

export const fetchData = (getItinearyData, priceSummary, conversionRate, getUpdateItinerary, splRt) => {
    const queryString = window.location.search
    const ind = getURLSearchParam('index')
    const departind = getURLSearchParam('departInd')
    const returnind = getURLSearchParam('returnInd')
    const flightsData = getItinearyData?.flights
    const adults = getItinearyData?.searchCriteria?.paxInfo?.adults
    const children = getItinearyData?.searchCriteria?.paxInfo?.children
    const price = getItinearyData?.fares?.[0]?.price
    const code = getItinearyData?.currencyConversionFactor?.code
    const rate = conversionRate?.rate
    const updatedDiscount = getItinearyData?.fares?.[0]?.discount
    const discount = updatedDiscount + priceSummary?.couponDiscountAmount
    const offer = getItinearyData?.meta?.offers?.[0]?.offer
    const totalPrice = !isEmpty(priceSummary?.fareDetails)
        ? finalPriceCalculation(priceSummary, getUpdateItinerary, splRt)
        : price
    const items = n =>
        flightsData[n].segments.map((item, index) => ({
            item_name: item.airlineName,
            index: ind
                ? Number(ind) + 1
                : departind && returnind
                    ? n === 0
                        ? Number(departind) + 1
                        : Number(returnind) + 1
                    : '',
            item_id: item.airline + '-' + item.flightNumber,
            currency: getCookie('utm_currency').split('|')[0] || 'INR',
            item_category: `${item?.departAirport} | ${item?.arrivalAirport}`,
            item_category2: getFormattedTime(flightsData[n]?.segments?.[index]?.duration),
            item_category3: 'N/A',
            item_category4: `${adults} | ${children}`,
            item_category5: String(flightsData[n].stopCount),
            price: priceConvertCurrency(totalPrice, code, rate, true),
            discount: discount > 0 ? priceConvertCurrency(discount, code, rate, true) : 0
        }))

    const arrDate = getItinearyData?.searchCriteria?.arrivalDate
    const depDate = getItinearyData?.searchCriteria?.departDate
    let itemsObj = !isEmpty(arrDate) ? [...items(0), ...items(1)] : items(0)
    return itemsObj
}

export const updateSrpData = (
    priceSummary,
    getItinearyData,
    conversionRate,
    getUpdateItinerary,
    splRt,
    itineraryId
) => {
    const srpData = localStorage.getItem('gaDataSrp') ? JSON.parse(localStorage.getItem('gaDataSrp')) : null
    if (itineraryId === srpData?.itineraryId) {
        const totalPrice = finalPriceCalculation(priceSummary, getUpdateItinerary, splRt)
        const srpDiscount = srpData?.discount || 0
        const couponDiscount = priceSummary?.couponDiscountAmount || 0
        const discount = srpDiscount + couponDiscount
        const code = getItinearyData?.currencyConversionFactor?.code
        const rate = conversionRate?.rate
        const newSrpData = srpData?.items?.map(obj => {
            if (obj?.price) {

                return {
                    ...obj,
                    price: priceConvertCurrency(totalPrice, code, rate, true),
                    discount: discount > 0 ? priceConvertCurrency(discount, code, rate, true) : 0
                }
            }
            return obj
        })
        localStorage.setItem(
            'gaDataSrp',
            JSON.stringify({ items: newSrpData, discount: srpDiscount, itineraryId: itineraryId || null })
        )
        return newSrpData
    } else {
        return null
    }
}

export const finalPriceCalculation = (priceSummary, getUpdateItinerary, splRt) => {
    let splRtDiscount = 0
    if (
        !isEmpty(splRt) &&
        getUpdateItinerary &&
        getUpdateItinerary.fareSelectionRequest &&
        getUpdateItinerary.fareSelectionRequest.fareKeys &&
        getUpdateItinerary.fareSelectionRequest.fareKeys[0] !== undefined &&
        getUpdateItinerary.fareSelectionRequest.fareKeys[1] !== undefined
    ) {
        let fareKeys = [...getUpdateItinerary.fareSelectionRequest.fareKeys]
        if (splRt[fareKeys[0]].fareClass === 'SPECIAL_RT' || splRt[fareKeys[1]].fareClass === 'SPECIAL_RT') {
            splRtDiscount = splRt.onward[0][0].diff + splRt.return[0][0].diff
        }
    }
    let gvRedeemedAmount = 0
    let {
        fareDetails = {},
        couponDiscountAmount = 0,
        gvDiscountAmount = 0,
        paybackDiscount = 0,
        TRAVEL_INSURANCE: travelIns = 0,
        BAGGAGE_INSURANCE: baggageIns = 0,
        MEDICAL_INSURANCE: medicalIns = 0,
        walletPromo,
        meals: mealsPrice = 0,
        baggage: baggagePrice = 0,
        seats: seatPrice = 0,
        Baggage_Protection: hepstarBaggageProtection = 0,
        superCoinsDiscount = 0
    } = priceSummary || {}
    if (walletPromo) {
        couponDiscountAmount = 0
    }
    let effectivePrice =
        fareDetails?.price -
        couponDiscountAmount +
        travelIns +
        baggageIns +
        hepstarBaggageProtection +
        medicalIns -
        splRtDiscount +
        mealsPrice +
        baggagePrice +
        seatPrice -
        superCoinsDiscount +
        (fareDetails?.convenienceFee || 0)
    let finalPrice = effectivePrice - gvDiscountAmount
    if (finalPrice > 0) {
        finalPrice = finalPrice - paybackDiscount
        gvRedeemedAmount = gvDiscountAmount
    } else {
        gvRedeemedAmount = effectivePrice
    }

    return finalPrice < 0 ? 0 : finalPrice
}

export const selectItem = (
    event,
    sectorKeys,
    resultsData,
    searchQuery,
    cardDetails,
    isIntl,
    index,
    conversionRate,
    itineraryId,
    round_trip,
    priceDetails,
    isSrpCouponDomRt,
    formattedSummaryOffer
) => {
    let offerMessege = ''
    let intlOfferData = "Get at {discountedPrice} with {couponCode}"
    const { code: currencyCode, rate = '1' } = conversionRate || {}
    const { sectors = {}, jsons = {}, cards } = resultsData || {}
    const { airline_names: airlineNames, searchType } = jsons || {}
    const array = []
    const offerData = cardDetails?.priceBreakup?.fare?.coupon_detail
    let message = (isIntl && offerData?.discountedPrice) ? intlOfferData : offerData?.message

    if (round_trip && !isSrpCouponDomRt && priceDetails?.totalCorpPrice === 0 && priceDetails?.savingPrice) {
        offerMessege = `Save ${priceConvertCurrency(priceDetails.savingPrice, currencyCode, rate, true)}` || 'N/A'
    } else if (round_trip && isSrpCouponDomRt) {
        offerMessege = formattedSummaryOffer || 'N/A'
    } else {
        offerMessege = !isEmpty(offerData)
            ? replacePlaceHolder(message, {
                discountAmount: formatCurrency({ price:offerData?.discountAmount, currencyCode, rate, sellingRate:1 }),
                discountedPrice: formatCurrency({ price:offerData?.discountedPrice, currencyCode, rate, sellingRate:1 }),
                couponCode: offerData?.couponCode
            })
            : ''
    }
    sectorKeys &&
        sectorKeys.forEach(item => {
            const { firstDeparture, lastArrival, stops, totalDuration, flights } = sectors ? sectors[item] : {}
            const { adults, childs, sellingCountry, from, to } = searchQuery
            const totalPrice = priceDetails?.totalPrice || cardDetails?.priceBreakup?.pr
            let obj = {}
            const items = flights?.segments?.map(item => ({
                item_name: jsons?.airline_names?.[item?.flightNumber?.split('-')?.[0]],
                currency: getCookie('utm_currency')?.split('|')[0] || 'INR',
                item_id: item?.flightNumber,
                item_category: `${item?.fr} | ${item?.to}`,
                item_category2: getFormattedTime(item?.dr),
                item_category3: offerMessege || 'N/A',
                item_category4: `${adults} | ${childs}`,
                item_category5: String(stops),
                price: priceConvertCurrency(totalPrice, currencyCode, rate, true),
                item_list_name: 'flight search',
                item_list_id: 'flight_search',
                index: index + 1,
                discount: !isEmpty(priceDetails?.savingPrice)
                    ? priceConvertCurrency(priceDetails?.savingPrice, currencyCode, rate, true)
                    : 0
            }))

            array.push(...items)
        })
    if (round_trip) {
        return array
    } else {
        const ecommerceValues = ecommerceGA({
            event: event,
            booking_date: `${searchQuery?.depart_date}${searchQuery?.return_date && ' | ' + searchQuery?.return_date}`,
            trip_type: searchType?.roundTrip ? 'round' : 'single',
            flight_type: isIntl ? 'International' : 'Domestic',
            source_destination: `${searchQuery?.from} | ${searchQuery?.to}`,
            loopArray: array
        })
        if (event === 'add_to_cart' && itineraryId) {
            localStorage.setItem(
                'gaDataSrp',
                JSON.stringify({
                    discount: priceDetails?.savingPrice || 0,
                    items: ecommerceValues.ecommerce?.items,
                    itineraryId: itineraryId
                })
            )
        }
        const { dataLayer = [] } = window || {}
        dataLayer.push({
            ...ecommerceValues
        })
    }
}

export const gaViewItems = (trip_type, items, discountedStatus, isIntl, searchQuery, flightId, flightRoute) => {
    try {

        const { dataLayer = [] } = window || {}
        dataLayer.push({
            event: 'view_item_list',
            trip_type: trip_type,
            platform: 'website',
            flight_type: isIntl ? 'International' : 'Domestic', // Flight type domestic | international
            booking_date: `${searchQuery?.depart_date}${searchQuery?.return_date && ' | ' + searchQuery?.return_date}`,
            onward_flight_id: flightId || 'N/A',
            source_destination: `${searchQuery?.from} | ${searchQuery?.to}`,
            onward_flight_route: discountedStatus === 'yes' ? `${flightRoute}_${searchQuery?.to}|${searchQuery?.from}` : 'N/A',
            discounted_flight_status: discountedStatus,
            ecommerce: {
                items: items
            }
        })
    } catch (error) {
        logError(error)
    }

}
