import { getFormattedDate } from 'utils/dateHelper'
import {
    getCookie,
    setPaymentCookie,
    isEmpty,
    fromQueryString,
    toQueryString,
    getUserAuthValues,
    getCTAuthCookie,
    jsonObjectToFormData,
    resolveKeysInObjectsArrays
} from 'utils/browserHelper'
import Clevertap from 'analytics'

import { DOMAIN, HOSTNAME } from 'constants/base'
import { DOMAIN_COUNTRY_MAP } from 'constants/flightResults'
import { getCurrencyByLocale, encodingValue } from 'utils/currencyHelper'
import { CUSTOMER_SUPPORT } from 'constants/customerSupport'

export const getSearchUrlParams = () => {
    const searchUrl = location.search || ''
    let params = searchUrl
    if (searchUrl.indexOf('?') > -1) {
        params = searchUrl.slice(1)
    }

    const urlParam = formSrpSearchQuery(params)
    return urlParam
}
export function removeWhiteSpacesFromObjectValues(obj) {
    const cleanedObject = {};
        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
                const value = obj[key];
                    if (typeof value === 'string') {
                        cleanedObject[key] = value.trim();
                    } else {
                        cleanedObject[key] = value;
                    }
            }
        }
    return cleanedObject;
}

export const formSrpSearchQuery = params => {
    let processedData = {}
    const data = fromQueryString(params)
    processedData.adults = parseInt(data.adults, 10)
    processedData.childs = parseInt(data.childs, 10)
    processedData.title = data.title || ''
    processedData.infants = parseInt(data.infants, 10)

    processedData.class = data.class || ''
    processedData.airline = data.airline || ''
    processedData.carrier = data.carrier || ''
    processedData.sd = data.sd
    processedData.page = data.page || ''
    processedData.sellingCountry = data.sellingCountry || getSellingCountry()
    processedData.ssfi = data.ssfi || ''
    processedData.flexi_search = data.flexi_search || ''
    processedData.ssfc = data.ssfc || ''
    processedData.origin = data.origin || null
    processedData.destination = data.destination || null
    processedData.intl = data.intl || ''
    processedData.sft = data.sft || ''
    if (data.dc_Enabled) {
        processedData.dc_Enabled = data.dc_Enabled
    }
    if (data.multicity) {
        processedData.from1 = data.from1 || ''
        processedData.to1 = data.to1 || ''
        processedData.from2 = data.from2 || ''
        processedData.to2 = data.to2 || ''
        processedData.from3 = data.from3 || ''
        processedData.to3 = data.to3 || ''
        processedData.depart_date_1 = !isEmpty(data.depart_date_1)
            ? getFormattedDate(data.depart_date_1, 'DD/MM/YYYY', 'D/M/YYYY')
            : ''
        processedData.depart_date_2 = !isEmpty(data.depart_date_2)
            ? getFormattedDate(data.depart_date_2, 'DD/MM/YYYY', 'D/M/YYYY')
            : ''
        processedData.depart_date_3 = !isEmpty(data.depart_date_3)
            ? getFormattedDate(data.depart_date_3, 'DD/MM/YYYY', 'D/M/YYYY')
            : ''
        processedData.multicity = data.multicity || ''
        processedData.num_legs = data.num_legs || ''
    } else {
        processedData.depart_date = !isEmpty(data.depart_date)
            ? getFormattedDate(data.depart_date, 'DD/MM/YYYY', 'D/M/YYYY')
            : ''
        processedData.return_date = !isEmpty(data.return_date)
            ? getFormattedDate(data.return_date, 'DD/MM/YYYY', 'D/M/YYYY')
            : ''

        processedData.from = data.from || ''
        processedData.to = data.to
    }
    const urlList = Object.keys(data)
    urlList.forEach((item,index)=>{
        if(item.includes('utm_')){
            processedData[item] =  data[item] 
        }
    })
    return removeWhiteSpacesFromObjectValues(processedData)
}

function getSellingCountry() {
    return DOMAIN_COUNTRY_MAP[DOMAIN]
}

export const formSrpQueryRequest = params => {
    return toQueryString(params)
}

export function _airportNameHelper(searchType, airportList, searchFrom) {
    let country = searchType[searchFrom]
    let countryName = airportList[searchFrom].c
    let countryAirportName = airportList[searchFrom].n
    let countryCode = searchFrom
    return `${countryName}, ${country} - ${countryAirportName} (${countryCode})`
}

function _createContentPostParams({ sectorKeys, sectors, jsons, airlineCodes }, prefixValue, multiIndex) {
    const { ap, searchType } = jsons || {}
    const postValues = {}
    const fareOptions = {
        prefix: [prefixValue],
        suffix: ['']
    }
    let multiCityIndex = searchType.multiCity ? `${multiIndex + 1}` : ''
    // TODO: for multiple sectors and multiple segments ...chnages the outerloop
    // this is for one sector with multiple segments
    sectorKeys.forEach((key, index) => {
        let sectorObj = sectors[key]
        const { flights: { segments = [] } = {} } = sectorObj || {}

        segments.forEach((segment, index) => {
            postValues[`${fareOptions.prefix}leg_from_${index + 1}${multiCityIndex}`] = segment.fr
            postValues[`${fareOptions.prefix}leg_to_${index + 1}${multiCityIndex}`] = segment.to
            postValues[`${fareOptions.prefix}leg_toAirportName_${index + 1}${multiCityIndex}`] =
                segment.departure.airportName.n
            postValues[`${fareOptions.prefix}leg_toCityName_${index + 1}${multiCityIndex}`] =
                segment.departure.airportName.c
            postValues[`${fareOptions.prefix}leg_arrives_date_${index + 1}${multiCityIndex}`] = getFormattedDate(
                segment.arrival.timestamp,
                'DD/MM/YYYY'
            )
            postValues[`${fareOptions.prefix}leg_airline_${index + 1}${multiCityIndex}`] = ''
            postValues[`${fareOptions.prefix}leg_arrives_${index + 1}${multiCityIndex + 1}`] = segment.arrival.time
            postValues[
                `${fareOptions.prefix}leg_via_${index + 1}${multiCityIndex}`
            ] = `${ap[segment.fr].c} to ${ap[segment.to].c}`
            postValues[`${fareOptions.prefix}leg_aircode_${index + 1}${multiCityIndex}`] = segment.flightNumber
            postValues[`${fareOptions.prefix}leg_departs_date_${index + 1}${multiCityIndex}`] = getFormattedDate(
                segment.departure.timestamp,
                'DD/MM/YYYY'
            )
            postValues[`${fareOptions.prefix}leg_cabin_type_${index + 1}${multiCityIndex}`] = segment.flightClass
            postValues[`${fareOptions.prefix}leg_departs_${index + 1}${multiCityIndex}`] = segment.departure.time
        })
    })

    return postValues
}
function createInitiateBooking(
    { journeyObj, jsons, sectors, isIntl, resultsData, firstCardIndex, secondCardIndex, fareKeyPersist },
    index
) {
    const { cardDetails, sectorKeys } = journeyObj || {}
    let { priceBreakup: { pr, fare = {}, sFare = {} } = {}, promos, maxStopsInSectors, dfd, airlineCodes } =
        cardDetails || {}
    const { psid, searchType, topLevelRateRules, emiJson, newGdsSplrtFare, nbs, promos: promosList = {} } = jsons || {}
    let firstFlight = {}
    let prefix
    if (index == 0) {
        prefix = 'out_'
    } else if (index == 1) {
        prefix = 'ret_'
    } else {
    }
    if (searchType.multiCity) {
        prefix = 'out_'
    }

    if (sFare.pr) {
        fare = sFare
    }
    const promoListArray = promosList ? Object.values(promosList) : []
    if (!isEmpty(firstCardIndex)) {
        const firstCardIndexSplitArr = firstCardIndex.split(':')
        const firstArrCard = resultsData.cards[firstCardIndexSplitArr[0]][firstCardIndexSplitArr[1]]
        let flightNumberArray = []

        firstArrCard.sectorKeys.forEach(key => {
            let sectorObj = sectors[key]
            const { flights: { segments = [] } = {} } = sectorObj || {}
            flightNumberArray = segments.map(segment => {
                return segment.flightNumber.split('-')[1]
            })
        })

        firstFlight = {
            oa: firstArrCard.airlineCodes.join(','),
            opr: firstArrCard.priceBreakup.pr,
            oac: flightNumberArray.join(',')
        }
    }
    if (!isEmpty(secondCardIndex)) {
        const secondCardIndexSplitArr = secondCardIndex.split(':')
        const firstArrCard = resultsData.cards[secondCardIndexSplitArr[0]][secondCardIndexSplitArr[1]]
        let flightNumberArray = []

        firstArrCard.sectorKeys.forEach(key => {
            let sectorObj = sectors[key]
            const { flights: { segments = [] } = {} } = sectorObj || {}
            flightNumberArray = segments.map(segment => {
                return segment.flightNumber.split('-')[1]
            })
        })
        firstFlight = {
            ...firstFlight,
            ra: firstArrCard.airlineCodes.join(','),
            rpr: firstArrCard.priceBreakup.pr
        }
    }

    let contentParams = {
        ..._createContentPostParams({ sectorKeys, sectors, jsons, airlineCodes }, prefix, index)
    }
    let fareParams = {
        cc: 1,
        rhc: 1,
        timeout: 3000,
        type: 'json',
        isMetaSearch: false,
        psid,
        topLevelRateRules,
        emiJson,
        intl: isIntl ? 'y' : 'n',
        newGdsSplrtFare,
        firstFlight: JSON.stringify(firstFlight),
        ...constructFareParam({
            isIntl,
            prefix,
            pr,
            fare,
            promos,
            nbs,
            maxStopsInSectors,
            fareKeyPersist,
            promoListArray,
            index,
            jsons
        })
    }

    return {
        ...fareParams,
        ...contentParams
    }
}
export const initiateBookingPayloadHelper = ({
    journyDetails = [],
    jsons,
    sectors,
    searchQuery,
    firstCardIndex,
    secondCardIndex,
    resultsData,
    isIntl,
    cfwEnabled = false,
    shortListObj = {},
    fareKeyPersist = '',
    ptbBannerData
}) => {
    const dfdArr = []
    let onwardTotalFlightDurationHour = 0
    let onwardTotalFlightDurationMin = 0

    let returnTotalFlightDurationHour = 0
    let returnTotalFlightDurationMin = 0

    let flightKeyOnward = []
    let flightKeyReturn = []
    let corpObj = {}
    let outFareObj = {}
    let retFareObj = {}
    let fareAndContentObj = journyDetails.map((journeyObj, index) => {
        const {
            cardDetails: { dfd },
            sectorKeys
        } = journeyObj || {}

        if (cfwEnabled) {
            const { cardDetails } = journeyObj || {}
            const {
                priceBreakup: { CORPObj: corpFare = {}, fare: normalFare = {}, sFare: splrtFare = {} } = {},
                maxStopsInSectors
            } = cardDetails || {}
            let prefix = ''

            let corpFareTypeKey = ''

            if (index === 0) {
                prefix = 'corp_out_'
                corpFareTypeKey = `corp_out_fare_subtype`
                outFareObj = {
                    NORMAL: {
                        fare_key: normalFare.fk,
                        price: normalFare && normalFare.pr && normalFare.pr.toString()
                    }
                }
            } else {
                prefix = 'corp_ret_'
                corpFareTypeKey = `corp_ret_fare_subtype`
                retFareObj = {
                    NORMAL: {
                        fare_key: normalFare.fk,
                        price: normalFare && normalFare.pr && normalFare.pr.toString()
                    }
                }
            }

            if (corpFare && corpFare.pr) {
                corpObj = {
                    ...corpObj,
                    ...constructFareParam({ isIntl, prefix, fare: corpFare, maxStopsInSectors, jsons }),
                    [corpFareTypeKey]: 'corporate'
                }

                if (index === 0) {
                    outFareObj = {
                        ...outFareObj,
                        CORP: {
                            fare_key: corpFare.fk,
                            price: corpFare && corpFare.pr && corpFare.pr.toString()
                        }
                    }
                } else {
                    retFareObj = {
                        ...retFareObj,
                        CORP: {
                            fare_key: corpFare.fk,
                            price: corpFare && corpFare.pr && corpFare.pr.toString()
                        }
                    }
                }
            }

            if (splrtFare && splrtFare.pr) {
                if (index === 0) {
                    outFareObj = {
                        ...outFareObj,
                        SPLRT: {
                            fare_key: splrtFare.fk,
                            price: splrtFare && splrtFare.pr && splrtFare.pr.toString()
                        }
                    }
                } else {
                    retFareObj = {
                        ...retFareObj,
                        SPLRT: {
                            fare_key: splrtFare.fk,
                            price: splrtFare && splrtFare.pr && splrtFare.pr.toString()
                        }
                    }
                }
            }
        }
        dfdArr.push(dfd)

        sectorKeys.map((key, indexSector, array) => {
            const sectorObj = sectors[key]
            const { totalSectorLayoverDuration, totalSectorDuration, flights: { segments = [] } = {} } = sectorObj || {}

            segments.forEach(segment => {
                if (indexSector === 0) {
                    flightKeyOnward.push(segment.fk)
                } else {
                    flightKeyReturn.push(segment.fk)
                }
            })
            if (index === 0) {
                onwardTotalFlightDurationHour += Math.floor((totalSectorLayoverDuration + totalSectorDuration) / 60)
                onwardTotalFlightDurationMin += (totalSectorLayoverDuration + totalSectorDuration) % 60
            } else {
                returnTotalFlightDurationHour += Math.floor((totalSectorLayoverDuration + totalSectorDuration) / 60)
                returnTotalFlightDurationMin += (totalSectorLayoverDuration + totalSectorDuration) % 60
            }
        })

        return createInitiateBooking(
            { journeyObj, jsons, sectors, isIntl, resultsData, firstCardIndex, secondCardIndex, fareKeyPersist },
            index
        )
    })

    const { showCorpFareForUser = '' } = jsons || {}
    let finalPayload = {}
    // const origin = _airportNameHelper(searchType, airportList, searchQuery.from)
    // const destination = _airportNameHelper(searchType, airportList, searchQuery.to)

    let cfwObj = {
        showCorpFareForUser: showCorpFareForUser === 'true',
        isWPUser: cfwEnabled
    }
    if (cfwEnabled) {
        cfwObj = {
            ...cfwObj,
            ...corpObj,

            fci: new Date().getTime()
        }
    }

    // common
    const searchParams = {
        ...searchQuery,
        sd: new Date().getTime()
    }
    const dfdObj = {
        dfd: dfdArr
    }
    fareAndContentObj.forEach(obj => {
        finalPayload = {
            ...finalPayload,
            ...obj
        }
    })

    const srpObj = {
        otd: `${onwardTotalFlightDurationHour}h ${onwardTotalFlightDurationMin}m`,
        rtd: !isEmpty(returnTotalFlightDurationHour)
            ? `${returnTotalFlightDurationHour}h ${returnTotalFlightDurationMin}m`
            : '',
        onwardFlightKey: flightKeyOnward.join('$$'),
        flightKeyReturn: flightKeyReturn.join('$$'),
        ptbDeals: isEmpty(ptbBannerData) ? '' : ptbBannerData.coupons && ptbBannerData.coupons[0],
        ...shortListObj
    }

    finalPayload = {
        ...searchParams,
        ...finalPayload,
        ...cfwObj,
        out_fare: JSON.stringify(outFareObj),
        ret_fare: JSON.stringify(retFareObj),
        srpBookInfo: JSON.stringify(srpObj),
        dfd: JSON.stringify(dfdObj.dfd),
        isPriceChangePopup: true
    }

    const formdata = jsonObjectToFormData(finalPayload)
    return formdata
}
function getSelectedCard (journyDetails,resultsData){
    const selectedCardId = []
    for(let i=0;i<journyDetails.length;i++){
        if(journyDetails[i].cardDetails){
            let id = resultsData?.sortData?.price_up[i].indexOf(journyDetails[i].cardDetails.id)
            selectedCardId.push(id)
        }
    }
    return selectedCardId
}
export const newInitiateBookingPayloadHelper = ({
    journyDetails = [],
    jsons,
    sectors,
    searchQuery,
    firstCardIndex,
    secondCardIndex,
    resultsData,
    isIntl,
    cfwEnabled = false,
    shortListObj = {},
    fareKeyPersist = '',
    ptbBannerData,
    bookerId,
    fareFamilyEnabled,
    fareFamilyData,
    getAbTest,
    couponCode,
    type = 'normal'
}) => {
    const {
        from = '',
        to = '',
        class: cabinType = '',
        adults,
        childs,
        infants,
        depart_date: departDate = '',
        return_date: returnDate = '',
        sft = ''
    } = searchQuery || {}
    const selectedCardListId = getSelectedCard(journyDetails,resultsData)
    
    const { psid , searchType = {}, dcFlow = false } = jsons || {}

    const intl = searchType?.isIntl ? 'y' : 'n'

    const { locale } = getCurrencyByLocale()

    let showCorpFareFlag = false
    const getDefaultCurObj = () => {
        const { currency, symbol } = getCurrencyByLocale()
        return `${currency}|${encodingValue(symbol)}`
    }
    const defaultCurObj = getCookie('utm_currency') || getDefaultCurObj()

    const source_Meta = getCookie('source_Meta')
    const source_affiliatefirstsource = getCookie('source_affiliatefirstsource')
    const source_firstpaidsource = getCookie('source_firstpaidsource')
    const source_firstsource = getCookie('source_firstsource')
    const onwardFareArr = []
    const onwardCorpFareArr = []

    let returnFareArr = []
    let returnCorpFareArr = []

    if (intl === 'y' && !isEmpty(returnDate)) {
        returnFareArr = onwardFareArr
        returnCorpFareArr = onwardCorpFareArr
    }
    // if(isEmpty(secondCardIndex)){}
    // const returnFareArr = isEmpty(secondCardIndex) ? onwardFareArr : []
    // const returnCorpFareArr = isEmpty(secondCardIndex) ? onwardCorpFareArr : []
    journyDetails.forEach((journeyObj, index) => {
        const { cardDetails } = journeyObj || {}
        const {
            priceBreakup: {
                pr,
                fare = {},
                CORPObj: corpFare = {},
                sFare = {},
                SPLRT: splrtObj = {},
                SPECIAL_FARE = {}
            } = {},
            dfd
        } = cardDetails || {}
        const {bp, fk, fareGroupInformation, solutionId, fb } = ((type === 'specialFare') && !isEmpty(SPECIAL_FARE)) ? SPECIAL_FARE : fare
        const { cc: sFareCC = '' } = sFare || {}
        if (index === 0) {
            let splRtFareOnwd = false
            if (!isEmpty(sFareCC) && splrtObj[sFareCC] && splrtObj[sFareCC].dfd && !(type === 'specialFare')) {
                const dfdValue = splrtObj[sFareCC].dfd

                if (!isEmpty(dfdValue)) {
                    const { pr: splrtPr, bp: splrtBp, fk: splrtFk, solutionIdMap, fareGroupInformation, fb } = splrtObj[
                        sFareCC
                    ][dfdValue]

                    let secKeys = journyDetails[1].sectorKeys[0].split('|')
                    let flightNoArr = []
                    for (let i = 0; i < secKeys.length; i++) {
                        flightNoArr.push(secKeys[i].split('-')[1])
                    }
                    let solutionKey = flightNoArr.join('$')
                    let obj = {
                        basePrice: splrtBp,
                        fareKey: fareKeyPersist ? fareKeyPersist : splrtFk,
                        fareType: dfdValue === 'R' ? 'REFUNDABLE' : 'NON_REFUNDABLE',
                        price: splrtPr,
                        fareClass: 'SPECIAL_RT'
                    }
                    if (!isEmpty(solutionIdMap) && solutionIdMap[solutionKey]) {
                        obj = {
                            ...obj,
                            solutionId: solutionIdMap[solutionKey],
                            comboFBC:
                                fareGroupInformation && fareGroupInformation.comboFareBasisCode
                                    ? fareGroupInformation.comboFareBasisCode
                                    : fb
                        }
                    }
                    onwardFareArr.push(obj)
                    splRtFareOnwd = true
                }
            } else if (
                fareFamilyEnabled &&
                !isEmpty(fareFamilyData) &&
                !isEmpty(fareFamilyData.fareDetails) &&
                (getAbTest.ff === 'b' || getAbTest.ff === 'c')
            ) {
                const { fareGroupInformation, fareDetails, vasInformation } = fareFamilyData || {}
                const { basePrice, fareClass, fareKey, fareType, totalPrice } = fareDetails || {}
                let onwardFare = {
                    basePrice,
                    fareKey,
                    fareType,
                    // fareKey: fareKeyPersist ? fareKeyPersist : fk,
                    // fareType: dfd === 'R' ? 'REFUNDABLE' : 'NON_REFUNDABLE',
                    price: totalPrice,
                    fareClass,
                    fareGroupInformation,
                    vasInformation
                    // ...fareFamilyData
                }
                if (solutionId) {
                    onwardFare = {
                        ...onwardFare,
                        solutionId: solutionId,
                        comboFBC:
                            fareGroupInformation && fareGroupInformation.comboFareBasisCode
                                ? fareGroupInformation.comboFareBasisCode
                                : fb
                    }
                }
                onwardFareArr.push(onwardFare)
            } else if (
                fareFamilyEnabled &&
                !isEmpty(fareGroupInformation) &&
                (getAbTest.ff === 'b' || getAbTest.ff === 'c')
            ) {
                let onwardFare = {
                    basePrice: bp,
                    fareKey: fareKeyPersist ? fareKeyPersist : fk,
                    fareType: dfd === 'R' ? 'REFUNDABLE' : 'NON_REFUNDABLE',
                    price: pr,
                    fareClass: 'RETAIL',
                    fareGroupInformation,
                    vasInformation: null
                }
                if (solutionId) {
                    onwardFare = {
                        ...onwardFare,
                        solutionId: solutionId,
                        comboFBC:
                            fareGroupInformation && fareGroupInformation.comboFareBasisCode
                                ? fareGroupInformation.comboFareBasisCode
                                : fb
                    }
                }
                onwardFareArr.push(onwardFare)
            } else {
                let onwardFare = {
                    basePrice: bp,
                    fareKey: fareKeyPersist ? fareKeyPersist : fk,
                    fareType: dfd === 'R' ? 'REFUNDABLE' : 'NON_REFUNDABLE',
                    price: ((type === 'specialFare') && !isEmpty(SPECIAL_FARE)) ? SPECIAL_FARE.pr : pr,
                    fareClass: 'RETAIL'
                }
                if (solutionId) {
                    onwardFare = {
                        ...onwardFare,
                        solutionId: solutionId,
                        comboFBC:
                            fareGroupInformation && fareGroupInformation.comboFareBasisCode
                                ? fareGroupInformation.comboFareBasisCode
                                : fb
                    }
                }
                onwardFareArr.push(onwardFare)
            }
            if (!isEmpty(corpFare)) {
                if (splRtFareOnwd) {
                    let onwardFare = {
                        basePrice: bp,
                        fareKey: fareKeyPersist ? fareKeyPersist : fk,
                        fareType: dfd === 'R' ? 'REFUNDABLE' : 'NON_REFUNDABLE',
                        price: pr,
                        fareClass: 'RETAIL'
                    }
                    if (solutionId) {
                        onwardFare = {
                            ...onwardFare,
                            solutionId: solutionId,
                            comboFBC:
                                fareGroupInformation && fareGroupInformation.comboFareBasisCode
                                    ? fareGroupInformation.comboFareBasisCode
                                    : fb
                        }
                    }
                    onwardFareArr.push(onwardFare)
                }
                showCorpFareFlag = true
                onwardCorpFareArr.push({
                    basePrice: corpFare.bp,
                    fareKey: fareKeyPersist ? fareKeyPersist : corpFare.fk,
                    fareType: dfd === 'R' ? 'REFUNDABLE' : 'NON_REFUNDABLE',
                    price: corpFare.pr,
                    fareClass: 'CORPORATE'
                })
            }
        } else {
            let splRtFareRet = false
            if (!isEmpty(sFareCC) && splrtObj[sFareCC] && splrtObj[sFareCC].dfd && !(type === 'specialFare')) {
                const dfdValue = splrtObj[sFareCC].dfd

                if (!isEmpty(dfdValue)) {
                    const { pr: splrtPr, bp: splrtBp, fk: splrtFk, solutionIdMap, fb, fareGroupInformation } = splrtObj[
                        sFareCC
                    ][dfdValue]

                    let secKeys = journyDetails[0].sectorKeys[0].split('|')
                    let flightNoArr = []
                    for (let i = 0; i < secKeys.length; i++) {
                        flightNoArr.push(secKeys[i].split('-')[1])
                    }
                    let solutionKey = flightNoArr.join('$')
                    let obj = {
                        basePrice: splrtBp,
                        fareKey: fareKeyPersist ? fareKeyPersist : splrtFk,
                        fareType: dfdValue === 'R' ? 'REFUNDABLE' : 'NON_REFUNDABLE',
                        price: splrtPr,
                        fareClass: 'SPECIAL_RT'
                    }
                    if (!isEmpty(solutionIdMap) && solutionIdMap[solutionKey]) {
                        obj = {
                            ...obj,
                            solutionId: solutionIdMap[solutionKey],
                            comboFBC:
                                fareGroupInformation && fareGroupInformation.comboFareBasisCode
                                    ? fareGroupInformation.comboFareBasisCode
                                    : fb
                        }
                    }
                    returnFareArr.push(obj)
                    splRtFareRet = true
                }
            } else {
                let returFareObj = {
                    basePrice: bp,
                    fareKey: fareKeyPersist ? fareKeyPersist : fk,
                    fareType: dfd === 'R' ? 'REFUNDABLE' : 'NON_REFUNDABLE',
                    price:((type === 'specialFare') && !isEmpty(SPECIAL_FARE)) ? SPECIAL_FARE.pr : pr,
                    fareClass: 'RETAIL'
                }
                if (solutionId) {
                    returFareObj = {
                        ...returFareObj,
                        solutionId: solutionId,
                        comboFBC:
                            fareGroupInformation && fareGroupInformation.comboFareBasisCode
                                ? fareGroupInformation.comboFareBasisCode
                                : fb
                    }
                }
                returnFareArr.push(returFareObj)
            }
            if (!isEmpty(corpFare)) {
                if (splRtFareRet) {
                    let returFareObj = {
                        basePrice: bp,
                        fareKey: fareKeyPersist ? fareKeyPersist : fk,
                        fareType: dfd === 'R' ? 'REFUNDABLE' : 'NON_REFUNDABLE',
                        price: pr,
                        fareClass: 'RETAIL'
                    }
                    if (solutionId) {
                        returFareObj = {
                            ...returFareObj,
                            solutionId: solutionId,
                            comboFBC:
                                fareGroupInformation && fareGroupInformation.comboFareBasisCode
                                    ? fareGroupInformation.comboFareBasisCode
                                    : fb
                        }
                    }
                    returnFareArr.push(returFareObj)
                }
                showCorpFareFlag = true
                returnCorpFareArr.push({
                    basePrice: corpFare.bp,
                    fareKey: fareKeyPersist ? fareKeyPersist : corpFare.fk,
                    fareType: dfd === 'R' ? 'REFUNDABLE' : 'NON_REFUNDABLE',
                    price: corpFare.pr,
                    fareClass: 'CORPORATE'
                })
            }
        }
    })

    let flightParamsObj = {
        cabinType: cabinType
            ? cabinType
                  .split(' ')
                  .join('_')
                  .toUpperCase()
            : 'ECONOMY',
        departDate: departDate ? getFormattedDate(departDate, 'YYYY-MM-DD', 'DD/MM/YYYY') : '',
        from,
        returnDate: returnDate ? getFormattedDate(returnDate, 'YYYY-MM-DD', 'DD/MM/YYYY') : '',
        onwardFares: [...onwardFareArr, ...onwardCorpFareArr],
        returnFares: [...returnFareArr, ...returnCorpFareArr],
        to
    }
    const paxInfo = {
        adults,
        children: childs,
        infants
    }
    let cfwObj = {}
    if (cfwEnabled) {
        cfwObj = {
            isCFWUser: cfwEnabled
        }
    }

    if (showCorpFareFlag) {
        cfwObj = { ...cfwObj, showCorpFare: showCorpFareFlag }
    }
    if (cfwEnabled) {
        cfwObj = {
            ...cfwObj,
            bookerId: bookerId
        }
    }
    const abFeatures = []
    abFeatures.push('FARE_BENEFIT_INFO')
    // if (getAbTest?.fareFamily === 'b'){
    //     abFeatures.push('FARE_BENEFIT_INFO')
    // }
    let utmSourceObj = {}

    if (source_Meta || source_affiliatefirstsource || source_firstpaidsource || source_firstsource) {
        utmSourceObj = {
            'utm-source': source_Meta || source_affiliatefirstsource || source_firstpaidsource || source_firstsource
        }
    }
    if ((getAbTest.otpless === 'c' || getAbTest.otpless === 'd') && DOMAIN_COUNTRY_MAP[DOMAIN] === 'IN') {
        abFeatures.push('SUPER_COIN_TOGGLE_ENABLED')
    }

    const itineraryMeta = {
        // ...cfwObj,
        domain: locale,
        externalApi: false,
        international: intl === 'y',
        multiCity: false,
        sid: psid,
        abFeatures,
        sourceType: 'B2C',
        abFeatures,
        utmCurrency: defaultCurObj && defaultCurObj.split('|')[0],
        convFeeRequired:false,
        ...cfwObj,
        ...utmSourceObj,
        couponCode,
        trackingDetails:{
            selectedCardListId,
        },
        sft,
        dcFlow: psid.includes('DC_Search')
    }
    const urlList = Object.keys(searchQuery)
    urlList.forEach((item,index)=>{
        if(item.includes('utm_')){
            const camelCaseKey = item.replace(/_([a-z])/g, (match, letter) => letter.toUpperCase());
            itineraryMeta.trackingDetails[camelCaseKey] =  searchQuery[item] 
        }
    })

    try {
        if(!isEmpty(returnDate)){
            flightParamsObj.onwardFares = flightParamsObj.onwardFares.map(fare=>{
                let updatedFare = {
                    ...fare
                }
                delete updatedFare.solutionId
                return updatedFare
            })
            flightParamsObj.returnFares = flightParamsObj.returnFares.map(fare=>{
                let updatedFare = {
                    ...fare
                }
                delete updatedFare.solutionId
                return updatedFare
            })
        }
    } catch(e){
        console.log(e)
    }
    

    const payload = {
        flightParams: [flightParamsObj],
        itineraryMeta,
        paxInfo
    }

    return payload
}

export const newMulticityPayloadHelper = ({
    journyDetails = [],
    jsons,
    sectors,
    searchQuery,
    firstCardIndex,
    secondCardIndex,
    resultsData,
    isIntl,
    cfwEnabled = false,
    shortListObj = {},
    fareKeyPersist = '',
    ptbBannerData,
    bookerId,
    getAbTest
}) => {
    const {
        from1: from1,
        from2: from2,
        from3: from3,
        to1: to1,
        to2: to2,
        to3: to3,
        class: cabinType = '',
        adults,
        childs,
        infants,
        depart_date_1 = '',
        depart_date_2 = '',
        depart_date_3 = '',
    } = searchQuery || {}
    const { psid , searchType = {} } = jsons || {}

    const intl = searchType?.isIntl ? 'y' : 'n'

    const { locale } = getCurrencyByLocale()

    let showCorpFareFlag = false
    const getDefaultCurObj = () => {
        const { currency, symbol } = getCurrencyByLocale()
        return `${currency}|${encodingValue(symbol)}`
    }
    const defaultCurObj = getCookie('utm_currency') || getDefaultCurObj()

    const source_Meta = getCookie('source_Meta')
    const source_affiliatefirstsource = getCookie('source_affiliatefirstsource')
    const source_firstpaidsource = getCookie('source_firstpaidsource')
    const source_firstsource = getCookie('source_firstsource')
    const onwardFareArr = []
    const flightParamsObj = []

    journyDetails.forEach((journeyObj, index) => {
        const { cardDetails } = journeyObj || {}
        const {
            priceBreakup: { pr, fare: { bp, fk }, CORPObj: corpFare = {}, sFare = {}, SPLRT: splrtObj = {} } = {},
            dfd
        } = cardDetails || {}

        flightParamsObj.push({
            cabinType: cabinType
                ? cabinType
                      .split(' ')
                      .join('_')
                      .toUpperCase()
                : 'ECONOMY',
            departDate: getFormattedDate(searchQuery[`depart_date_${index + 1}`], 'YYYY-MM-DD', 'DD/MM/YYYY'),
            from: searchQuery[`from${index + 1}`],

            onwardFares: [
                {
                    basePrice: bp,
                    fareKey: fareKeyPersist ? fareKeyPersist : fk,
                    fareType: dfd === 'R' ? 'REFUNDABLE' : 'NON_REFUNDABLE',
                    price: pr,
                    fareClass: 'RETAIL'
                }
            ],
            returnFares: [],
            to: searchQuery[`to${index + 1}`]
        })
    })

    const paxInfo = {
        adults,
        children: childs,
        infants
    }
    let utmSourceObj = {}

    if (source_Meta || source_affiliatefirstsource || source_firstpaidsource || source_firstsource) {
        utmSourceObj = {
            'utm-source': source_Meta || source_affiliatefirstsource || source_firstpaidsource || source_firstsource
        }
    }
    const itineraryMeta = {
        domain: locale,
        externalApi: false,
        international: intl === 'y',
        multiCity: true,
        sid: psid,
        sourceType: 'B2C',
        utmCurrency: defaultCurObj && defaultCurObj.split('|')[0],
        convFeeRequired: false,
        ...utmSourceObj
    }

    const payload = {
        flightParams: flightParamsObj,
        itineraryMeta,
        paxInfo
    }

    return payload
}
export const transistPayloadHelper = ({ sectorKeys, sectors, searchQuery }) => {
    const { from = '', to = '', return_date: returnDate = '' } = searchQuery || {}
    const onwardSectorArray = []
    const returnSectorArray = []

    sectorKeys.forEach((sector, index) => {
        if (!isEmpty(sectors[sector])) {
            const { flights: { segments = [] } = {} } = sectors[sector] || {}

            segments.forEach(segment => {
                const {
                    arrival: { timestamp: arrivalTimeStamp, airportCode: arrivalAirportCode } = {},
                    departure: { timestamp: departureTimestamp, airportCode: departureAirportCode } = {}
                } = segment || []

                const sectorObj = {
                    dep: departureAirportCode,
                    arr: arrivalAirportCode,
                    ddt: getFormattedDate(departureTimestamp, 'YYYY-MM-DDTHH:mm:ss+00:00'),
                    adt: getFormattedDate(arrivalTimeStamp, 'YYYY-MM-DDTHH:mm:ss+00:00 ')
                }
                if (index === 0) {
                    onwardSectorArray.push(sectorObj)
                } else if (index === 1) {
                    returnSectorArray.push(sectorObj)
                }
            })
        }
    })

    let payload = {
        scfrom: from,
        scto: to,
        jt: !isEmpty(returnDate) ? 'RT' : 'OW',
        os: [...onwardSectorArray]
    }
    if (!isEmpty(returnSectorArray)) {
        payload = {
            ...payload,
            rs: [...returnSectorArray]
        }
    }

    return payload
}
export const freeBaggageInfoPayloadHelper = ({ cardDetails, jsons, sectorKeys, sectors, searchQuery, type='normal' }) => {
    const { adults, childs, infants, intl, class: classType } = searchQuery || {}
    const { priceBreakup: { fare = {}, SPECIAL_FARE = {} } = {} } = cardDetails || {}

    const {
        searchType: { disp_currency, sellingCountry }
    } = jsons || {}

    var queryObj = {
        adt: adults,
        chd: childs,
        inf: infants,
        intl: intl === 'n' ? false : true,
        scr: disp_currency,
        sct: sellingCountry,
        stype: 'B2C'
    }

    var fareObj = {
        ctype:
            classType === 'Economy'
                ? 'E'
                : classType === 'Premium Economy'
                ? 'P'
                : classType === 'Business'
                ? 'B'
                : classType,
        fk: type === 'specialFare' ? SPECIAL_FARE.fk : fare.fk,
        mi: false, // TODO: value is missing in nodejs response
        ncib: fare.ncib
    }
    if (fare.fareGroupInformation && fare.fareGroupInformation.comboFareBasisCode) {
        fareObj = { ...fareObj, combo_fbc: fare.fareGroupInformation.comboFareBasisCode }
    }

    let segmentArrFinal = []
    sectorKeys.forEach((key, index) => {
        segmentArrFinal.push({ seg: _createSegmentParmas(key, sectors, fare, false, index, {}, true) })
    })

    const payload = {
        sc: { ...queryObj },
        sol: [
            {
                fl: [...segmentArrFinal],
                fre: { ...fareObj }
            }
        ]
    }

    return payload
}

export const fareMiniRulePayloadHelper = ({
    cardDetails,
    sectorKeys,
    sectors,
    searchQuery,
    jsons,
    CORPObj,
    journeyType
}) => {
    const { adults, childs, infants, intl, from, to } = searchQuery || {}
    const { airlineCodes, priceBreakup = {} } = cardDetails || {}

    let { fare = {} } = priceBreakup || {}
    if (!isEmpty(CORPObj)) {
        fare = CORPObj
    }
    const {
        searchType: { disp_currency, sellingCountry }
    } = jsons || {}

    const queryObj = {
        ADT: adults,
        CHD: childs,
        INF: infants,
        intl: intl.toLocaleUpperCase(),
        scr: disp_currency,
        sct: sellingCountry,
        from:sectors[Array.isArray(sectorKeys)? sectorKeys[0]:sectorKeys].firstDeparture.airportCode,
        to:sectors[Array.isArray(sectorKeys)? sectorKeys[0]:sectorKeys].lastArrival.airportCode,
        roundtrip: 'N'
    }

    let operatinAirline
    let basePrice

    if (!isEmpty(journeyType)) {
        basePrice = journeyType === 'depart' ? fare.mi_oadtbp : fare.mi_radtbp
        operatinAirline = journeyType === 'depart' ? airlineCodes[0] : airlineCodes[1]
    } else {
        operatinAirline = airlineCodes[0]
        basePrice = fare.adtbp
    }

    const fareParams = {
        sid: jsons.psid, // TODO : need to figure it out
        bp: basePrice,
        oa: operatinAirline,
        routed: fare.fk && fare.fk.indexOf('route_id') > -1 ? 'Y' : 'N',
        // TODO : need to figure it out
        // rs: [],
        yq: fare.fs
    }

    let osArrFinal = []
    let rsArrFinal = []
    if (Array.isArray(sectorKeys)) {
        sectorKeys.forEach((key, index) => {
            if (index === 0) {
                osArrFinal = _createSegmentParmas(key, sectors, fare, true, index)
            } else {
                rsArrFinal = _createSegmentParmas(key, sectors, fare, true, index)
            }
        })
    } else {
        // Intl RT MultiAirline Case
        osArrFinal = _createSegmentParmas(sectorKeys, sectors, fare, true, 0, journeyType)
    }

    const segmentParams = {
        os: osArrFinal,
        rs: rsArrFinal.length ? rsArrFinal : [],
        roundtrip: rsArrFinal.length ? 'Y' : 'N'
    }
    const payload = {
        ...queryObj,
        ...fareParams,
        ...segmentParams
    }

    return payload
}

const _createSegmentParmas = (key, sectors, fare, miniRuleFlag, idx, journeyType, freeBaggageInfo) => {
    const segmentArray = []
    let sectorObj = sectors[key]
    const { flights: { segments = [] } = {} } = sectorObj || {}
    const airlineNumArr = []
    let newCount = 0
    segments.forEach((segment, index) => {
        const {
            arrival: { timestamp: arrivalTimeStamp, airportCode: arrivalAirportCode } = {},
            departure: { timestamp: departureTimestamp, airportCode: departureAirportCode } = {},
            flightNumber,
            fk,
            oa
        } = segment || {}

        let segmentObj = {}
        if (miniRuleFlag) {
            if (airlineNumArr.indexOf(flightNumber) !== -1) {
                newCount--
            }

            let fareBase
            let baseClass
            if (!isEmpty(journeyType)) {
                if (journeyType === 'depart') {
                    fareBase = fare && fare.mi_ofb && fare.mi_ofb.split(',')[idx]
                    baseClass = fare.bc.split(',')[idx]
                } else if (journeyType === 'return') {
                    fareBase = fare && fare.mi_rfb && fare.mi_rfb.split(',')[idx]
                    baseClass = fare.bc.split(',')[idx]
                }
            } else {
                if (idx === 1 && fare && fare.fb && fare.fb.split(',').length === 1) {
                    fareBase = fare && fare.fb && fare.fb.split(',')[0]
                    baseClass = fare.bc.split(',')[idx]
                } else {
                    fareBase = fare && fare.fb && fare.fb.split(',')[newCount]
                    baseClass = fare.bc.split(',')[newCount]
                }
            }
            airlineNumArr.push(flightNumber)
            newCount++
            segmentObj = {
                bc: baseClass,
                fb: fareBase,
                al: flightNumber.split('-')[0],
                dd: `${getFormattedDate(departureTimestamp, 'DD-MM-YYYY')}`,
                sup: fk.split(`_${segment.flightNumber}`)[0]
            }
        } else {
            segmentObj = {
                ad: `${getFormattedDate(arrivalTimeStamp, 'YYYY-MM-DD HH:mm')}`,
                al: flightNumber.split('-')[0],
                dd: `${getFormattedDate(departureTimestamp, 'YYYY-MM-DD HH:mm')}`,
                oa,
                bc: fare.bc.split(',')[idx]
            }
        }
        let segmentArrObj = {
            ...segmentObj,
            arr: arrivalAirportCode,
            dep: departureAirportCode,
            fn: flightNumber.split('-')[1]
        }
        if (freeBaggageInfo && fare.fb) {
            segmentArrObj = { ...segmentArrObj, fareBasisCode: fare.fb }
        }
        if (fare.fk) {
            segmentArrObj = { ...segmentArrObj, fareKey: fare.fk }
        }
        segmentArray.push(segmentArrObj)
    })

    return segmentArray
}

export const fareMiniRulePayloadHelperNew = ({
    cardDetails,
    sectorKeys,
    sectors,
    searchQuery,
    jsons,
    CORPObj,
    journeyType
}) => {
    const { adults, childs, infants, intl, from, to } = searchQuery || {}
    const { airlineCodes, priceBreakup = {} } = cardDetails || {}

    let { fare = {} } = priceBreakup || {}
    if (!isEmpty(CORPObj)) {
        fare = CORPObj
    }
    const {
        searchType: { disp_currency, sellingCountry }
    } = jsons || {}

    const queryObj = {
        ADT: adults,
        CHD: childs,
        INF: infants,
        intl: intl.toLocaleUpperCase(),
        scr: disp_currency,
        sct: sellingCountry,
        from: sectors[Array.isArray(sectorKeys) ? sectorKeys[0] : sectorKeys].firstDeparture.airportCode,
        to: sectors[Array.isArray(sectorKeys) ? sectorKeys[0] : sectorKeys].lastArrival.airportCode,
        roundtrip: 'N'
    }

    let operatinAirline
    let basePrice

    if (!isEmpty(journeyType)) {
        basePrice = journeyType === 'depart' ? fare.mi_oadtbp : fare.mi_radtbp
        operatinAirline = journeyType === 'depart' ? airlineCodes[0] : airlineCodes[1]
    } else {
        operatinAirline = airlineCodes[0]
        basePrice = fare.adtbp
    }

    const fareParams = {
        sid: jsons.psid, // TODO : need to figure it out
        bp: basePrice,
        oa: operatinAirline,
        routed: fare.fk && fare.fk.indexOf('route_id') > -1 ? 'Y' : 'N',
        // TODO : need to figure it out
        // rs: [],
        yq: fare.fs
    }

    let osArrFinal = []
    let rsArrFinal = []
    let newCount = 0
    if (Array.isArray(sectorKeys)) {
        sectorKeys.forEach((key, index) => {
            if (index === 0) {
                ;[osArrFinal, newCount] = _createSegmentParmasNew(key, sectors, fare, true, index, '', false, newCount)
            } else {
                ;[rsArrFinal, newCount] = _createSegmentParmasNew(key, sectors, fare, true, index, '', false, newCount)
            }
        })
    } else {
        // Intl RT MultiAirline Case
        ;[osArrFinal, newCount] = _createSegmentParmasNew(
            sectorKeys,
            sectors,
            fare,
            true,
            0,
            journeyType,
            false,
            newCount
        )
    }

    const segmentParams = {
        os: osArrFinal,
        rs: rsArrFinal.length ? rsArrFinal : [],
        roundtrip: rsArrFinal.length ? 'Y' : 'N'
    }
    const payload = {
        ...queryObj,
        ...fareParams,
        ...segmentParams
    }

    return payload
}

const _createSegmentParmasNew = (key, sectors, fare, miniRuleFlag, idx, journeyType, freeBaggageInfo, newCount) => {
    const segmentArray = []
    let sectorObj = sectors[key]
    const { flights: { segments = [] } = {} } = sectorObj || {}
    const airlineNumArr = []
    segments.forEach((segment, index) => {
        const {
            arrival: { timestamp: arrivalTimeStamp, airportCode: arrivalAirportCode } = {},
            departure: { timestamp: departureTimestamp, airportCode: departureAirportCode } = {},
            flightNumber,
            fk,
            oa
        } = segment || {}

        let segmentObj = {}
        if (miniRuleFlag) {
            if (airlineNumArr.indexOf(flightNumber) !== -1) {
                newCount--
            }

            let fareBase
            let baseClass
            if (!isEmpty(journeyType)) {
                if (journeyType === 'depart') {
                    fareBase = fare && fare.mi_ofb && fare.mi_ofb.split(',')[idx]
                    baseClass = fare.bc.split(',')[idx]
                } else if (journeyType === 'return') {
                    fareBase = fare && fare.mi_rfb && fare.mi_rfb.split(',')[idx]
                    baseClass = fare.bc.split(',')[idx]
                }
            } else {
                if (idx === 1 && fare && fare.fb && fare.fb.split(',').length === 1) {
                    fareBase = fare && fare.fb && fare.fb.split(',')[0]
                    baseClass = fare.bc.split(',')[idx]
                } else {
                    fareBase = fare && fare.fb && fare.fb.split(',')[newCount]
                    baseClass = fare.bc.split(',')[newCount]
                }
            }
            airlineNumArr.push(flightNumber)
            newCount++
            segmentObj = {
                bc: baseClass,
                fb: fareBase,
                al: flightNumber.split('-')[0],
                dd: `${getFormattedDate(departureTimestamp, 'DD-MM-YYYY')}`,
                sup: fk.split(`_${segment.flightNumber}`)[0]
            }
        } else {
            segmentObj = {
                ad: `${getFormattedDate(arrivalTimeStamp, 'YYYY-MM-DD HH:mm')}`,
                al: flightNumber.split('-')[0],
                dd: `${getFormattedDate(departureTimestamp, 'YYYY-MM-DD HH:mm')}`,
                oa,
                bc: fare.bc.split(',')[idx]
            }
        }
        let segmentArrObj = {
            ...segmentObj,
            arr: arrivalAirportCode,
            dep: departureAirportCode,
            fn: flightNumber.split('-')[1]
        }
        if (freeBaggageInfo && fare.fb) {
            segmentArrObj = { ...segmentArrObj, fareBasisCode: fare.fb }
        }
        if (fare.fk) {
            segmentArrObj = { ...segmentArrObj, fareKey: fare.fk }
        }
        segmentArray.push(segmentArrObj)
    })

    return [segmentArray, newCount]
}

export const departureArrivalLabel = (airportCode, airportlist, searchType) => {
    const city = getCity(airportCode, airportlist)
    const countryCode = searchType[airportCode] || ''
    if (countryCode) {
        return `${airportCode} - ${city}, ${countryCode}`
    }
    return `${airportCode}`
}
export const getCity = (airportCode, airportlist) => {
    const { c: city = '' } = airportlist[airportCode] || {}
    return city
}
function constructFareParam({
    isIntl,
    prefix,
    pr,
    fare,
    promos,
    nbs,
    maxStopsInSectors,
    fareKeyPersist,
    promoListArray,
    index,
    jsons
}) {
    let obj = {}
    let farekey = !isEmpty(fareKeyPersist) ? fareKeyPersist : fare.fk
    let multiIndex = jsons.searchType.multiCity ? `${index + 1}` : ''
    if (isIntl) {
        obj = {
            leg_fare_basis_1: fare.fb,
            fci: new Date().getTime(),
            price: fare.pr,
            nbs,
            fare_key: farekey,
            taxes: fare.t,
            base_price: fare.bp,
            disc: fare.ds,
            per_adult_base_price: fare.adtbp
        }
        if (promos && promos[0] && promos[0].ptext) {
            obj.deal = encodeURIComponent(promos[0].ptext)
        }
    } else {
        obj[`${prefix}leg_fare_basis_1${multiIndex}`] = fare.fb
        obj[`${prefix}price${multiIndex}`] = fare.pr
        obj[`${prefix}fare_key${multiIndex}`] = farekey
        obj[`${prefix}taxes${multiIndex}`] = fare.t
        obj[`${prefix}base_price${multiIndex}`] = fare.bp
        obj[`${prefix}disc${multiIndex}`] = fare.ds
        obj[`${prefix}per_adult_base_price${multiIndex}`] = fare.adtbp
        obj[`${prefix}adult_base${multiIndex}`] = fare.abp
        obj[`${prefix}no_legs`] = maxStopsInSectors
        if (promos && promos[0] && promos[0].ptext) {
            obj[`${prefix}deal`] = encodeURIComponent(promos[0].ptext)
        }
        if (promoListArray && promoListArray.length) {
            obj[`${prefix}deal`] = encodeURIComponent(offerAppend(promoListArray))
        }
    }
    return obj
}
export function offerAppend(promoListArray) {
    let offerUrl = ''
    for (let i = 0; i < promoListArray.length; i++) {
        offerUrl += promoListArray[i].ptext + `_${promoListArray[i].plink}` + '||'
    }
    return offerUrl
}
export const userLoggedInObj = () => {
    //willBreak
    let IS_EMAIL_EXIST = false
    let IS_USERID_EXIST = false
    let IS_CT_AUTH_EXIST = false

    const { email = '', userId = '' } = getUserAuthValues()

    IS_EMAIL_EXIST = email !== undefined && email.length > 0

    if (userId !== undefined) {
        IS_USERID_EXIST = true
    }

    const ctAuthCookie = getCTAuthCookie()
    if (!isEmpty(ctAuthCookie)) {
        IS_CT_AUTH_EXIST = ctAuthCookie.length > 0
    }

    return {
        isLoggedIn: IS_USERID_EXIST && IS_CT_AUTH_EXIST,
        email,
        userId
    }
}

export const addShortlistHelper = ({ cardDetails, jsons, sectorKeys, sectors, searchQuery, userData }) => {
    const {
        adults,
        childs,
        infants,
        intl,
        class: cabinType,
        from,
        to,
        depart_date: departDate,
        return_date: returnDate
    } = searchQuery || {}

    const {
        searchType: { sellingCountry, sellCurr },
        ap,
        airline_names
    } = jsons || {}

    const { priceBreakup: { fare = {} } = {} } = cardDetails || {}
    const { email = '', isLoggedIn = false } = userData || {}

    // // For user login or not
    // const userLoggedInObj = userLoggedInObj()
    // const { isLoggedIn = false, email = '' } = userLoggedInObj || {}

    // metaDataObj
    let metaDataObj = {
        domain: HOSTNAME, // TODO: hardcoded right now . Value will be: ${DOMAIN}

        price: fare.pr
    }
    if (!isEmpty(email) && email !== 'null') {
        metaDataObj = {
            ...metaDataObj,
            emailId: email
        }
    }

    let segmentFlightKeyArrOnward = []
    let segmentFlightKeyArrReturn = []
    let onwardSectorTotalDuration
    let returnSectorTotalDuration = null

    let totalsegmentDetailsObj = {}

    sectorKeys.forEach((key, index) => {
        let sectorObj = sectors[key]
        const { flights: { segments = [] } = {}, totalSectorDuration } = sectorObj || {}
        let segementDetailsArr = []

        segments.forEach(segment => {
            const {
                fk = '',
                flightNumber = '',
                arrival: { airportCode: arrivalAirportCode = '', date: arrivalDate = '', time: arrivalTime = '' } = {},
                departure: {
                    airportCode: departureAirportCode = '',
                    date: departureDate = '',
                    time: departureTime = ''
                } = {},
                durationTime: { hh, mm }
            } = segment || {}

            if (index === 0) {
                segmentFlightKeyArrOnward.push(fk)
            } else if (index === 1) {
                segmentFlightKeyArrReturn.push(fk)
            }

            const totalDuration = (hh * 60 + mm) * 60

            segementDetailsArr.push({
                airline: flightNumber.split('-')[0],
                airlineName: airline_names[flightNumber.split('-')[0]],
                arrivalAirport: arrivalAirportCode,
                arrivalDate: arrivalDate,
                arrivalTime: arrivalTime,
                departAirport: departureAirportCode,
                departDate: departureDate,
                departTime: departureTime,
                duration: totalDuration,
                flightNumber: flightNumber.split('-')[1]
            })
        })

        const segmentDetailsObj = {}
        if (!isEmpty(segementDetailsArr)) {
            segementDetailsArr.forEach((obj, index) => {
                segmentDetailsObj[index] = obj
            })
        }

        if (index === 0) {
            onwardSectorTotalDuration = totalSectorDuration * 60
            totalsegmentDetailsObj['onward'] = segmentDetailsObj
        } else if (index === 1) {
            returnSectorTotalDuration = totalSectorDuration
            totalsegmentDetailsObj['return'] = segmentDetailsObj
        }
    })

    // flightKeyObj
    let flightKeyObj = {
        onward: segmentFlightKeyArrOnward.join('|')
    }
    if (!isEmpty(segmentFlightKeyArrReturn)) {
        flightKeyObj = {
            ...flightKeyObj,
            return: segmentFlightKeyArrReturn.join('|')
        }
    }

    // flightDetailsObj
    let flightDetailsObj = {
        onwardDuration: onwardSectorTotalDuration,
        onwardSegments: totalsegmentDetailsObj['onward'],
        returnDuration: returnSectorTotalDuration,
        returnSegments: totalsegmentDetailsObj['return'] === undefined ? {} : totalsegmentDetailsObj['return']
    }

    // SearchObject
    const serachObj = {
        adults: adults.toString(),
        infants: infants.toString(),
        children: childs.toString(),
        from,
        to,
        cabinType,
        international: intl === 'y',
        sellingCountry,
        departDateString: departDate || '',
        returnDateString: returnDate || '',
        sellingCurrency: sellCurr,
        sourceType: 'B2C',
        destination: ap[to] && ap[to].c,
        origin: ap[from] && ap[from].c
    }

    const payload = {
        sc: serachObj,
        metaData: metaDataObj,
        flightKey: flightKeyObj,
        fd: [flightDetailsObj]
    }

    return payload
}

export const flightKeyObjHelper = ({ sectorKeys, sectors }) => {
    let segmentFlightKeyArrOnward = []
    let segmentFlightKeyArrReturn = []

    sectorKeys.forEach((key, index) => {
        let sectorObj = sectors[key]
        const { flights: { segments = [] } = {} } = sectorObj || {}

        segments.forEach(segment => {
            const { fk = '' } = segment || {}

            if (index === 0) {
                segmentFlightKeyArrOnward.push(fk)
            } else if (index === 1) {
                segmentFlightKeyArrReturn.push(fk)
            }
        })
    })

    let flightKeyObj = {
        onward: segmentFlightKeyArrOnward.join('|')
    }
    if (!isEmpty(segmentFlightKeyArrReturn)) {
        flightKeyObj = {
            ...flightKeyObj,
            return: segmentFlightKeyArrReturn.join('|')
        }
    }

    return flightKeyObj
}

export const shorlistFetchHelper = ({ searchQueryParams, jsons }) => {
    const { adults, childs, infants, intl, class: cabinType, from, to, depart_date: departDate } =
        searchQueryParams || {}

    const {
        searchType: { sellingCountry, sellCurr }
    } = jsons || {}

    const { userId = '' } = getUserAuthValues()

    // SearchObject
    const serachObj = {
        adults: adults.toString(),
        infants: infants.toString(),
        children: childs.toString(),
        from,
        to,
        cabinType,
        international: intl === 'y',
        sellingCountry,
        departDateString: departDate,
        sellingCurrency: sellCurr,
        sourceType: 'B2C'
    }
    return {
        sc: serachObj,
        userId
    }
}

export const ptbFetchHelper = searchQueryParams => {
    const {
        adults,
        childs,
        infants,
        intl,
        class: classType,
        from,
        to,
        depart_date: departDate,
        return_date: returnDate
    } = searchQueryParams || {}

    const serachObj = {
        adults,
        infants,
        childs,
        from,
        to,
        class: classType,
        intl: intl === 'y',
        depart_date: departDate,
        return_date: returnDate,
        source: 'srp',
        sd: new Date().getTime(),
        page: 'loaded',
        timeout: 3000,
        cc: 1,
        rhc: 1,
        airline: '',
        carrier: '',
        search_ver: 'V2'
    }
    return {
        ...serachObj
    }
}

/**
 * Remove same price cards by checking in each card's samePriceCards array. This only happens for Intl
 *
 * @param {string[]} sortedOneWayCardids - array of sorted cardIds Eg. ['0:1','0:2']
 * @param {string[]} cards - array of cards taken from resultsData Eg. [{},{},{}]
 * @return {string[]} - array of cardIds with same price cards filtered out
 *
 */
export function filterRepeatingSamePriceCards(sortedOneWayCardids, cards) {
    // TODO Anoop - Add same price cards Map
    let samePriceCardIdsToRemove = []
    let samePriceCardIdsToRetain = []

    let samePriceCardIdsMap = {}

    sortedOneWayCardids.forEach(cardId => {
        const cardIdIndex = cardId.split(':')[1]
        const card = cards[cardIdIndex]
        if (card.samePriceCards.length !== 0) {
            if (!samePriceCardIdsToRemove.includes(card.id)) {
                let unsortedSamePriceCards = []
                samePriceCardIdsToRetain.push(card.id)
                samePriceCardIdsToRemove.push(...card.samePriceCards)
                // Create map here
                // let validSamePriceCardIds = []
                card.samePriceCards.forEach(cardId => {
                    const indexOfCardId = sortedOneWayCardids.indexOf(cardId)
                    if (indexOfCardId > -1) {
                        // validSamePriceCardIds.push(cardId)
                        unsortedSamePriceCards.push({ val: cardId, position: indexOfCardId })
                    }
                })
                unsortedSamePriceCards.sort((a, b) => a.position - b.position)
                samePriceCardIdsMap[card.id] = unsortedSamePriceCards.map(spCard => spCard.val)
            }
        } else samePriceCardIdsToRetain.push(card.id)
    })

    // return samePriceCardIdsToRetain
    return { samePriceCardIdsToRetain, samePriceCardIdsMap }
}

export function checkingFlightsValidTimings(sectorTime) {
    const ARRIVAL_DEPARTURE_OVERLAP_TIME = 0
    const ARRIVAL_DEPARTURE_THRESHOLD = 30
    if (sectorTime < ARRIVAL_DEPARTURE_OVERLAP_TIME) {
        return {
            msg:
                'The onward and return flights are overlapping with each other. Please change one of these selections.',
            type: 'LESS_THAN_ZERO'
        }
    } else if (sectorTime < ARRIVAL_DEPARTURE_THRESHOLD) {
        return {
            msg: 'The onward and return flights are very close to each other. You might miss your return flight.',
            type: 'LESS_THAN_THIRTY'
        }
    } else {
        return {
            msg: '',
            type: 'GREATER_THAN_THIRTY'
        }
    }
}

export function airportSuggestConverter(data) {
    data = data.map(loc => {
        const {
            code,
            arabic: [airport]
        } = loc
        return {
            k: code,
            v: airport
        }
    })
    return data
}

export function getCustomerSupport(lang, domain) {
    return CUSTOMER_SUPPORT[lang][domain] || ''
}

export function getTotalFlightPrice(journyDetails = []) {
    let totalPrice = 0

    journyDetails.forEach((journeyObj, index) => {
        const { cardDetails } = journeyObj || []
        const { priceBreakup: { pr, CORPObj: corpFare = {} } = {} } = cardDetails || {}

        // eslint-disable-next-line no-lonely-if
        if (!isEmpty(corpFare)) {
            totalPrice += corpFare.pr
        } else {
            totalPrice += pr
        }
    })

    return totalPrice
}

export function isNewFlow(getAbTestObj) {
    // const [email] = decodeURIComponent(getCookie('userid') || '').split('|')

    // const emailMap = [
    //     'snehal.k@cleartrip.com',
    //     'prasanna.a@cleartrip.com',
    //     'sanjay.g@cleartrip.com',
    //     'devanshu.bhatt@cleartrip.com',
    //     'abhishek.choudhary@cleartrip.com',
    //     'abhenavh.prakash@cleartrip.com',
    //     'naresh.kapse@cleartrip.com',
    //     'vineet.arora@cleartrip.com',
    //     'lnarayanan@cleartrip.com',
    //     'anil.patil@cleartrip.com',
    //     'nandan.suresh@cleartrip.com',
    //     'yashmin.b@cleartrip.com',
    //     'kanchana.amaresh@cleartrip.com',
    //     'veerakesari.v@cleartrip.com',
    //     'nabeel.thurakkal@cleartrip.com',
    //     'raghav.gupta@cleartrip.com',
    //     'vidyasagar.lonare@cleartrip.com',
    //     'anurhea.dutta@cleartrip.com',
    //     'mannu.matta@cleartrip.com',
    //     'manoj.s@cleartrip.com',
    //     'kesha.shah@cleartrip.com',
    //     'kiran.kumar@cleartrip.com',
    //     'sahil.k@cleartrip.com',
    //     'akshay.nandakumar@cleartrip.com',
    //     'sreejith.puthenveettil@cleartrip.com',
    //     'kanika.kaul@cleartrip.com',
    //     'sapna.nayak@cleartrip.com'
    // ]
    // const usermisc = getCookie('usermisc') || ''

    const forcedBentoItn = getCookie('forcedOldItn') || ''
    const cShellCookie = getCookie('cShell') || ''

    let isNewFlowFlag = true
    const ua = window.navigator.userAgent

    if (forcedBentoItn) {
        return false
    }
    if (cShellCookie) {
        return false
    }

    // As of now for IE browser we are showing old flow.
    // IE 10 or older, IE 11, Edge (IE 12+),
    if ((isNewFlowFlag && ua.indexOf('MSIE ') > 0) || ua.indexOf('Trident/') > 0 || ua.indexOf('Edge/') > 0) {
        isNewFlowFlag = false
    }
    isPaymentFlow(getAbTestObj)
    return isNewFlowFlag
}

export function isPaymentFlow(getAbTestObj) {
    let domain = DOMAIN_COUNTRY_MAP[location.origin]
    if (Object.keys(getAbTestObj).length > 0) {
        Clevertap.event('Payment_UI_AB', {
            is_bento_payment_OTHERS: getAbTestObj.is_bento_payment_OTHERS,
            domain: window.location.hostname,
            [`is_bento_payment_${domain}`]: getAbTestObj[`is_bento_payment_${domain}`]
        })
    } else {
        Clevertap.event('Payment_UI_AB', {
            domain: window.location.hostname,
            is_bento_payment_OTHERS: 'N/A',
            [`is_bento_payment_${domain}`]: 'N/A'
        })
    }
    if (getAbTestObj && getAbTestObj[`is_bento_payment_${domain}`] == 'a') {
        setPaymentCookie('isBentoPayment', true, 1)
    }
    if (getAbTestObj && getAbTestObj.is_bento_payment_OTHERS == 'a') {
        setPaymentCookie('isBentoPayment', true, 1)
    }

    if (getAbTestObj && getAbTestObj[`payment_cc_emi_${domain}_desktop`] == 'a') {
        setPaymentCookie('payment_cc_emi_desktop', true, 1)
    }
}

export const isCtUser = () => {
    const [email] = decodeURIComponent(getCookie('userid') || '').split('|')
    return email.indexOf('@cleartrip.com') > -1
}

export const ffPayloadHelper = ({ cardDetails, sectorKeys, sectors, searchQuery, jsons, fareOfferList }) => {
    const { adults, childs, infants, intl, from, to } = searchQuery || {}
    const { priceBreakup = {} } = cardDetails || {}
    const { FAMILY_FARE, fare } = priceBreakup || []
    const {
        searchType: { disp_currency, sellingCountry }
    } = jsons || {}

    let segmentArr = []

    const searchCriteriaQuery = {
        domain: sellingCountry,
        currency: disp_currency,
        sourceType: 'B2C',
        adults: adults,
        children: childs,
        infants: infants,
        tripType: 'ONE_WAY',
        cabinType: 'ECONOMY',
        from: from,
        to: to,
        isInternational: intl === 'y' ? true : false
    }

    if (!isEmpty(sectorKeys) && !isEmpty(sectors)) {
        sectorKeys.forEach((key, index) => {
            let sectorObj = sectors[key]
            const { flights: { segments = [] } = {} } = sectorObj || {}
            segments.forEach(segment => {
                const {
                    flightNumber = '',
                    oa = '',
                    arrival: {
                        airportCode: arrivalAirportCode = '',
                        date: arrivalDate = '',
                        time: arrivalTime = '',
                        timestamp: arrivalTimestamp
                    } = {},
                    departure: {
                        airportCode: departureAirportCode = '',
                        date: departureDate = '',
                        time: departureTime = '',
                        timestamp: departureTimestamp
                    } = {}
                } = segment || {}

                const arrivalDateTime = getFormattedDate(arrivalTimestamp, 'YYYY-MM-DD HH:mm')
                const departureDateTime = getFormattedDate(departureTimestamp, 'YYYY-MM-DD HH:mm')

                segmentArr.push({
                    airline: flightNumber.split('-')[0],
                    arrivalAirport: arrivalAirportCode,
                    arrivalDateTime: arrivalDateTime,
                    departAirport: departureAirportCode,
                    departDateTime: departureDateTime,
                    flightNumber: flightNumber.split('-')[1],
                    operatingAirline: oa
                })
            })
        })
    }
    const flights = [
        {
            departAirport: from,
            arrivalAirport: to,
            segments: segmentArr
        }
    ]
    const fares = []

    if (!isEmpty(FAMILY_FARE)) {
        Object.values(FAMILY_FARE).forEach(fareObj => {
            const { fareGroupInformation, fareHash, pricingInfo, fk, fb, bc, pr, bp, isNocheckInBaggage } = fareObj
            const { fareGroup, fareGroupName, productClass, fareGroupDisplayName } = fareGroupInformation || {}
            fares.push({
                fareGroupInformation: {
                    fareGroup,
                    fareGroupName,
                    fareGroupDisplayName,
                    productClass
                },
                fareDetails: {
                    fareKey: fk,
                    fareHash: fareHash,
                    fareBasisCode: fb,
                    bookingClass: bc,
                    basePrice: bp,
                    fareType: 'REFUNDABLE',
                    totalPrice: pr,
                    fareClass: 'RETAIL'
                },
                pricingInfo
            })
        })
    }

    if (!isEmpty(fare)) {
        const { fareGroupInformation, fareHash, pricingInfo, fk, fb, bc, pr, bp, isNocheckInBaggage } = fare
        const { fareGroup, fareGroupName, productClass, fareGroupDisplayName } = fareGroupInformation || {}
        fares.push({
            fareGroupInformation: {
                fareGroup,
                fareGroupName,
                fareGroupDisplayName,
                productClass
            },
            fareDetails: {
                fareKey: fk,
                fareHash: fareHash,
                fareBasisCode: fb,
                bookingClass: bc,
                basePrice: bp,
                fareType: 'REFUNDABLE',
                totalPrice: pr,
                fareClass: 'RETAIL'
            },
            pricingInfo,
            isNocheckInBaggage: isNocheckInBaggage || false
        })
    }

    const payload = {
        searchCriteria: {
            ...searchCriteriaQuery
        },
        solutions: [
            !isEmpty(fareOfferList)
                ? {
                      fareOfferList,
                      flights,
                      fares
                  }
                : {
                      flights,
                      fares
                  }
        ]
    }

    return payload
}

export const constructEmiPriceUuid = (flightData, fareId = 'REGULAR', type = 'regular', resultsData = {}, getAbTest = {}) => {
    let uuid = ''
    flightData.sectorKeys.forEach((secKey, secIndex) => {
        let sectorData = resultsData?.sectors[secKey] || {}
        if (secIndex > 0) {
            uuid += ','
        }
        sectorData && sectorData.flights &&
        sectorData.flights.segments.forEach((item = {}, index) => {
            if (index > 0) {
                uuid += '|'
            }
            uuid += item.flightNumber
        })
        uuid = `${uuid}_${fareId}`
    })
    uuid = `${uuid}|price_${getFlightPrice(flightData, type, getAbTest)}`
    return uuid
}

export const getFlightPrice = (flightData, type, getAbTest = {}) => {
    const {
        priceBreakup: {
            fare: { 
                coupon_detail: {
                    discountedPrice = 0
                } = {}
            } = {},
            SPECIAL_FARE: {
                strike_off_details = {}
            } = {}
        } = {}
    } = flightData || {}
    if (type === 'highRps' && getAbTest.highRps === 'd') {
        return discountedPrice
    }
    if (type === "splFare" && !isEmpty(strike_off_details)) {
        return strike_off_details?.updatedPrice
    }
    return flightData?.priceBreakup?.pr
}
